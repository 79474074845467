

// 初始状态
const initState = {
    status: ""
}

// 操作 app配置 状态信息的 reducer 函数
export const appConfig = (state = initState, action) => {
    // const { type, payload } = action

    return state
}