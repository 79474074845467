/** 页面标识 */
export const PageTag = {
    /**
    * WEBSITE
    */
    WEBSITE: "WEBSITE",

    /**
    * INSTALL_GOOD_USER
    */
    INSTALL_GOOD_USER: "INSTALL_GOOD_USER",

    /**
    * LANDING
    */
    LANDING: "LANDING",

    /**
    * LAUNCH
    */
    LAUNCH: "LAUNCH",

    /**
    * LAUNCH_REFERRER
    */
    LAUNCH_REFERRER: "LAUNCH_REFERRER",

    /**
    * LOGIN
    */
    LOGIN: "LOGIN",

    /**
    * LOGIN_OTP
    */
    LOGIN_OTP: "LOGIN_OTP",

    /**
    * LOGIN_GOOD_USER
    */
    LOGIN_GOOD_USER: "LOGIN_GOOD_USER",

    /**
     * HOME
     */
    HOME: "HOME",

    /**
     * PROOF
     */
    PROOF: "PROOF",

    /**
     * PROOF_MOBILE
     */
    PROOF_MOBILE: "PROOF_MOBILE",

    /**
    * PROOF_CONTACT
    */
    PROOF_CONTACT: "PROOF_CONTACT",

    /**
    * PROOF_FREQUENTLY_APPS
    */
    PROOF_FREQUENTLY_APPS: "PROOF_FREQUENTLY_APPS",

    /**
    * PROOF_FACE
    */
    PROOF_FACE: "PROOF_FACE",

    /**
    * PROOF_OCR
    */
    PROOF_OCR: "PROOF_OCR",

    /**
    * PROOF_WALLET
    */
    PROOF_WALLET: "PROOF_WALLET",

    /**
    * PROOF_GOOD_USER_WALLET
    */
    PROOF_GOOD_USER_WALLET: "PROOF_GOOD_USER_WALLET",

    /**
    * PROOF_OTHER_LOANS
    */
    PROOF_OTHER_LOANS: "PROOF_OTHER_LOANS",

    /**
     * APPLY
     */
    APPLY: "APPLY",

    /**
     * APPLY_PROCESSING
     */
    APPLY_PROCESSING: "APPLY_PROCESSING",

    /**
     * REVIEW
     */
    REVIEW: "REVIEW",

    /**
     * REJECT
     */
    REJECT: "REJECT",

    /**
     * LOANING
     */
    LOANING: "LOANING",

    /**
    * REPAY_LIST
    */
    REPAY_LIST: "REPAY_LIST",

    /**
    * REPAY_DETAIL
    */
    REPAY_DETAIL: "REPAY_DETAIL",

    /**
    * REPAY_PIN_PROCESSING
    */
    REPAY_PIN_PROCESSING: "REPAY_PIN_PROCESSING",

    /**
    * REPAY_PIN_FAIL
    */
    REPAY_PIN_FAIL: "REPAY_PIN_FAIL",

    /**
    * REPAY_PIN_SUCCESS
    */
    REPAY_PIN_SUCCESS: "REPAY_PIN_SUCCESS",

    /**
     * REPAY
     */
    REPAY: "REPAY",

    /**
    * GUIDE
    */
    GUIDE: "GUIDE",

    /**
    * PRIVACY_POLICY
    */
    PRIVACY_POLICY: "PRIVACY_POLICY",

    /**
    * TERM_OF_USE
    */
    TERM_OF_USE: "TERM_OF_USE",

    /**
    * ME
    */
    ME: "ME",

    /**
    * SETTING
    */
    SETTING: "SETTING",

    /**
    * MY_ICON
    */
    MY_ICON: "MY_ICON",

    /**
    * MY_PROFILE
    */
    MY_PROFILE: "MY_PROFILE",

    /**
    * FEEDBACK
    */
    FEEDBACK: "FEEDBACK"
}

/** 事件标识 */
export const EventTag = {
    LOGIN_MOBILE_COMMIT: "LOGIN_MOBILE_COMMIT",
    LOGIN_MOBILE_PRIVACY: "LOGIN_MOBILE_PRIVACY",
    LOGIN_MOBILE_INPUT: "LOGIN_MOBILE_INPUT",

    LOGIN_OTP_WA: "LOGIN_OTP_WA",
    LOGIN_OTP_SMS: "LOGIN_OTP_SMS",
    LOGIN_OTP_COMMIT: "LOGIN_OTP_COMMIT",
    LOGIN_OTP_SEND: "LOGIN_OTP_SEND",

    LOGIN_AUTO_FLAG: "LOGIN_AUTO_FLAG",

    PRODUCT_LIST_TAB_APPLY: "PRODUCT_LIST_TAB_APPLY",
    PRODUCT_LIST_TAB_APPLIED: "PRODUCT_LIST_TAB_APPLIED",
    PRODUCT_LIST_EMPTY_APPLY: "PRODUCT_LIST_EMPTY_APPLY",
    PRODUCT_LIST_APPLY_ITEM: "PRODUCT_LIST_APPLY_ITEM",
    PRODUCT_LIST_APPLIED_ITEM: "PRODUCT_LIST_APPLIED_ITEM",

    PROOF: "PROOF_",
    PROOF_GOOD_USER_WALLET_COMMIT: "PROOF_GOOD_USER_WALLET_COMMIT",

    PROOF_CONTACT_COMMIT: "PROOF_CONTACT_COMMIT",
    PROOF_CONTACT_ADD: "PROOF_CONTACT_ADD",

    PROOF_SELFIE_DELETE: "PROOF_SELFIE_DELETE",
    PROOF_SELFIE_PICK: "PROOF_SELFIE_PICK",
    PROOF_SELFIE_COMMIT: "PROOF_SELFIE_COMMIT",

    PROOF_DOC_COMMIT: "PROOF_DOC_COMMIT",
    PROOF_DOC_TYPE: "PROOF_DOC_TYPE",
    PROOF_DOC_ID_CARD_FRONT: "PROOF_DOC_ID_CARD_FRONT",
    PROOF_DOC_ID_CARD_BACK: "PROOF_DOC_ID_CARD_BACK",
    PROOF_DOC_DRIVING_FRONT: "PROOF_DOC_DRIVING_FRONT",
    PROOF_DOC_DRIVING_BACK: "PROOF_DOC_DRIVING_BACK",
    PROOF_DOC_PASSPORT_FRONT: "PROOF_DOC_PASSPORT_FRONT",

    PROOF_WALLET_COMMIT: "PROOF_WALLET_COMMIT",
    PROOF_WALLET_ADD: "PROOF_WALLET_ADD",
    PROOF_WALLET_ADD_POP_CHANNEL: "PROOF_WALLET_ADD_POP_CHANNEL",
    PROOF_WALLET_ADD_POP_COMMIT: "PROOF_WALLET_ADD_POP_COMMIT",
    PROOF_WALLET_SAME_CHANNEL_POP_CANCEL: "PROOF_WALLET_SAME_CHANNEL_POP_CANCEL",
    PROOF_WALLET_SAME_CHANNEL_POP_COMMIT: "PROOF_WALLET_SAME_CHANNEL_POP_COMMIT",
    PROOF_WALLET_LESS_2PER_POP_CANCEL: "PROOF_WALLET_LESS_2PER_POP_CANCEL",
    PROOF_WALLET_LESS_2PER_POP_COMMIT: "PROOF_WALLET_LESS_2PER_POP_COMMIT",
    PROOF_WALLET_SUBMIT_POP_COMMIT: "PROOF_WALLET_SUBMIT_POP_COMMIT",

    APPLY_COMMIT: "APPLY_COMMIT",

    LOGIN_GOOD_USER_OTP_SEND: "LOGIN_GOOD_USER_OTP_SEND",
    LOGIN_GOOD_USER_OTP_COMMIT: "LOGIN_GOOD_USER_OTP_COMMIT",
    LOGIN_GOOD_USER_APPLY_OTP_SEND: "LOGIN_GOOD_USER_APPLY_OTP_SEND",
    LOGIN_GOOD_USER_APPLY_OTP_COMMIT: "LOGIN_GOOD_USER_APPLY_OTP_COMMIT",

    REVIEW_REFRESH: "REVIEW_REFRESH",
    REVIEW_WALLET_ADD: "REVIEW_WALLET_ADD",

    LOANING_REFRESH: "LOANING_REFRESH",
    LOANING_WALLET_ADD: "LOANING_WALLET_ADD",

    REJECT_DC_LIST_ITEM_INSTALL: "REJECT_DC_LIST_ITEM_INSTALL",

    REPAY_LIST_EMPTY_APPLY: "REPAY_LIST_EMPTY_APPLY",
    REPAY_LIST_ITEM_REPAY: "REPAY_LIST_ITEM_REPAY",
    REPAY_DETAIL_COMMIT: "REPAY_DETAIL_COMMIT",

    PIN_REPAY_SUCCESS_APPLY_AGAIN: "PIN_REPAY_SUCCESS_APPLY_AGAIN",
    PIN_REPAY_SUCCESS_PID_COPY: "PIN_REPAY_SUCCESS_PID_COPY",

    PIN_REPAY_PROCESSING_PAY_AGAIN: "PIN_REPAY_PROCESSING_PAY_AGAIN",
    PIN_REPAY_FAIL_PAY_AGAIN: "PIN_REPAY_FAIL_PAY_AGAIN",

    INSTALL_OPEN: "INSTALL_OPEN",
    INSTALL_REINSTALL: "INSTALL_REINSTALL",
    INSTALL_OPEN_CHROME: "INSTALL_OPEN_CHROME",
    INSTALL_OPEN_GOOGLE: "INSTALL_OPEN_GOOGLE",
    INSTALL_OPEN_GOOGLE_GO: "INSTALL_OPEN_GOOGLE_GO",

    WEBSITE_INSTALL: "WEBSITE_INSTALL",
    LANDING_INSTALL: "LANDING_INSTALL",
    GOOD_USER_INSTALL: "GOOD_USER_INSTALL",
    GOOD_USER_INSTALL_SUCCESS: "GOOD_USER_INSTALL_SUCCESS",

    ADD_INSTALL_SHOW: "ADD_INSTALL_SHOW",
    ADD_INSTALL_AGREE: "ADD_INSTALL_AGREE",
    ADD_INSTALL_NOT_AGREE: "ADD_INSTALL_NOT_AGREE",
    ADD_INSTALL_DISMISS: "ADD_INSTALL_DISMISS",
    ADD_INSTALL_NOT_SUPPORT: "ADD_INSTALL_NOT_SUPPORT",

}

/**
 * 返回页面结束标识
 * @param {string} pageTag 
 * @returns 页面结束标识
 */
export const endPageFlag = (pageTag) => {
    return pageTag + "_END";
}

/**
 * 返回页面开始标识
 * @param {string} pageTag 
 * @returns 页面开始标识
 */
export const startPageFlag = (pageTag) => {
    return pageTag + "_START";
}

/**
 * 返回点击事件标识
 * @param {string} eventTag 
 * @returns 页面开始标识
 */
export const clickEventFlag = (eventTag) => {
    return eventTag + "_CLICK";
}

/**
 * 返回输入事件标识
 * @param {string} eventTag 
 * @returns 页面开始标识
 */
export const inputEventFlag = (eventTag) => {
    return eventTag + "_INPUT";
}
