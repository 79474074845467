// RegionRedirect.js
import React from 'react';
import { Redirect } from 'react-router-dom';
import DefaultLang from './useLanguage';
import { toLog } from './utils/utils';

export const addRegion = (path = '') => {
    const region = DefaultLang.region;
    const catalog = DefaultLang.catalog;
    const pathFormat = path.replace(/^\/+/, '');

    return `/${region}/${catalog}/${pathFormat}`;
};

const RegionRedirect = () => {

    DefaultLang.detectLanguage();
    const region = DefaultLang.region;
    const catalog = DefaultLang.catalog;

    let launch = "";
    if (catalog === "website") {
        launch = ""
    }
    else if (catalog === "landing") {
        launch = ""
    }
    else if (catalog === "h5market") {
        const children = DefaultLang.children;
        if (children && children.length > 1) {
            launch = children
        } else {
            launch = "/launch"
        }
    }
    // 根据地区码设置重定向路径
    const redirectPath = `/${region}/${catalog}${launch}`;

    return <Redirect to={redirectPath} />;
};

export default RegionRedirect;
