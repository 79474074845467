
import DefaultLang from "@/useLanguage";
import { isNullObject } from "@/utils/utils";


/** 认证步骤：基本信息 */
export const ProofStepBasic = "personal_info"

/** 认证步骤：工作信息 */
export const ProofStepWork = "work_info"

/** 认证步骤：联系人信息 */
export const ProofStepContact = "contact_info"

/** 认证步骤：号码验证信息 */
export const ProofStepMobile = "mobile_verify"

/** 认证步骤：号码验证信息 */
export const ProofStepFrequentlyUsedApps = "frequently_used_apps"

/** 认证步骤：人脸验证信息 */
export const ProofStepFace = "selfie_invo"
export const ProofStepFace2 = "selfie_info"
export const ProofStepFace3 = "sefie_info"

/** 认证步骤：ocr验证信息 */
export const ProofStepOcr = "ocr_info"

/** 认证步骤：bvn信息 */
export const ProofStepBvn = "bvn_info"

/** 认证步骤：银行卡信息 */
export const ProofStepAccount = "account_info"

/** 认证步骤：其它贷款信息 */
export const ProofOtherLoans = "extra_info"


/**
 * 判断是否完成所有认证步骤
 * @param {*} list 步骤列表
 * @returns 
 */
export const isAllStepsFinish = list => {
    if (!isNullObject(list)) {
        return !(list.length > 0)
    }

    return true
}

/**
 * 判斷Action是否為选择地址
 * @param {*} action 
 * @returns 
 */
export const isActionAddressPick = action => {
    switch (action) {
        case "ADDRESS_PICK": return true
        default: return false
    }
}

/**
 * 判斷Action是否為选择日期
 * @param {*} action 
 * @returns 
 */
export const isActionDatePick = action => {
    switch (action) {
        case "DATE_PICK": return true
        default: return false
    }
}

/**
 * 判斷Action是否為选择银行
 * @param {*} action 
 * @returns 
 */
export const isActionBankPick = action => {
    switch (action) {
        case "BANK_PICK": return true
        default: return false
    }
}

/**
 * 判斷Action是否為列表选择
 * @param {*} action 
 * @returns 
 */
export const isActionSelect = action => {
    switch (action) {
        case "CONTACT_GROUP_RELATION": return true
        case "CREDIT_REPORT": return true
        case "GENDER_PICK": return true
        case "SELECT": return true
        default: return false
    }
}

/**
 * 判斷Action是否為输入
 * @param {*} action 
 * @returns 
 */
export const isActionInput = action => {
    switch (action) {
        case "INPUT": return true
        case "CONTACT_GROUP_NAME": return true
        case "CONTACT_GROUP_PHONE": return true
        default: return false
    }
}

/**
 * 判斷是否为联系人认证页
 * @param {*} step 
 * @returns 
 */
export const isProofContact = step => {
    switch (step) {
        case ProofStepContact: return true
        default: return false
    }
}


/**
 * 判斷是否为工作认证页
 * @param {*} step 
 * @returns 
 */
export const isProofWork = step => {
    switch (step) {
        case ProofStepWork: return true
        default: return false
    }
}

/**
 * 判斷是否为号码认证页
 * @param {*} step 
 * @returns 
 */
export const isProofMobile = step => {
    switch (step) {
        case ProofStepMobile: return true
        default: return false
    }
}

/**
 * 判斷是否为常用app认证页
 * @param {*} step 
 * @returns 
 */
export const isProofFrequentlyUsedApps = step => {
    switch (step) {
        case ProofStepFrequentlyUsedApps: return true
        default: return false
    }
}

/**
 * 判斷是否为人脸认证页
 * @param {*} step 
 * @returns 
 */
export const isProofFace = step => {
    switch (step) {
        case ProofStepFace: return true
        case ProofStepFace2: return true
        case ProofStepFace3: return true

        default: return false
    }
}

/**
 * 判斷是否为OCR认证页
 * @param {*} step 
 * @returns 
 */
export const isProofOcr = step => {
    switch (step) {
        case ProofStepOcr: return true
        default: return false
    }
}

/**
 * 判斷是否为绑卡认证页
 * @param {*} step 
 * @returns 
 */
export const isProofBankAccount = step => {
    switch (step) {
        case ProofStepAccount: return true
        default: return false
    }
}

/**
 * 判斷是否为other loans认证页
 * @param {*} step 
 * @returns 
 */
export const isProofOtherLoans = step => {
    switch (step) {
        case ProofOtherLoans: return true
        default: return false
    }
}

/**
 * 判斷是否为绑卡认证页-法语
 * @param {*} step 
 * @returns 
 */
export const isProofBankAccountOfFrZone = step => {
    const isBankAccount = (step.indexOf(ProofStepAccount) !== -1);
    const isFrZone = DefaultLang.isFrZone();

    return isBankAccount && isFrZone;
}
