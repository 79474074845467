// AsLoading.js
import ReactDOM from 'react-dom/client';
import styles from './asLoading.module.css';

import pic_loading from "@/assets/pic_loading.gif"

class AsLoading {
    static instance = null;
    static didShow = false;

    static show(message, hideAfterSeconds) {
        if (AsLoading.didShow === true) return;

        document.body.style.overflow = 'hidden';

        if (!AsLoading.instance) {
            const toastContainer = document.createElement('div');
            toastContainer.id = 'as-loading-container';
            document.body.appendChild(toastContainer);
            AsLoading.instance = ReactDOM.createRoot(toastContainer);
            AsLoading.didShow = true;
        }

        AsLoading.instance.render(
            <div className={`${styles.asLoadingOverlay} ${styles.asLoadingFadeIn}`}>
                <div className={styles.asLoadingContent}>
                    <img className={styles.asLoadingIcon} src={pic_loading} alt='' />
                </div>
            </div>
        );

        // 自动隐藏 Toast 提示
        if (hideAfterSeconds && hideAfterSeconds > 200) {
            setTimeout(() => {
                AsLoading.hide();
            }, hideAfterSeconds);
        }
    }

    static hide() {
        document.body.style.overflow = '';

        if (AsLoading.instance) {
            const container = document.getElementById('as-loading-container');
            if (container) {
                container.classList.add(styles.asLoadingFadeOut);
                container.addEventListener('animationend', () => {
                    AsLoading.instance?.unmount();
                    AsLoading.instance = null;
                    container.remove();
                    AsLoading.didShow = false;
                }, { once: true });
            }
        }

        // if (AsLoading.instance) {
        //     AsLoading.instance.unmount();
        //     AsLoading.instance = null;
        // }
        // AsLoading.didShow = false;
    }
}

export default AsLoading;
