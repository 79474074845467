
import DefaultLang from "@/useLanguage"
import LoginStatus from "./loginStatus"
import { isEmptyString, toLog } from "./utils"

// 用户 信息的本地缓存键名
const KEY_USER_INFO = "KEY_USER_INFO_CLERE"

// 设备标识 信息的本地缓存键名
const KEY_DEVICE_ID_INFO = "KEY_DEVICE_ID_INFO_CLERE"

// advertId 信息的本地缓存键名
const KEY_ADVERT_ID_INFO = "KEY_ADVERT_ID_INFO_CLERE"

// aduid 信息的本地缓存键名
const KEY_ADUID_INFO = "KEY_ADUID_INFO_CLERE"

// 已经评价过app 信息的本地缓存键名
const KEY_APP_REVIEW = "KEY_APP_REVIEW_CLERE"

// chat mobile 信息的本地缓存键名
const KEY_CHAT_MOBILE = "KEY_CHAT_MOBILE_CLERE"

// 订单号 信息的本地缓存键名
const KEY_ORDER_ID = "KEY_ORDER_ID_CLERE"

// 时区转发 信息的本地缓存键名
const KEY_AD_SOURCE_URL = "KEY_AD_SOURCE_URL_CLERE"

// 宗教 信息的本地缓存键名
const KEY_RELIGION_CLERE = "KEY_RELIGION_CLERE"

// 登录mobile的本地缓存键名
const KEY_LOGIN_MOBILE_CLERE = "KEY_LOGIN_MOBILE_CLERE"

/**
 * 从本地缓存中获取用户信息
 */
export const getUserInfo = () => {
    // 判断是否存在登录信息对象，且 token 有效
    const isValidToken = (token) => typeof token === "string" && token.trim().length > 0;
    const queryKey = (KEY_USER_INFO + "_" + DefaultLang.region).toUpperCase();

    if (!LoginStatus.loginInfo || !isValidToken(LoginStatus.loginInfo.token)) {
        try {
            const cachedInfo = localStorage.getItem(queryKey);
            LoginStatus.loginInfo = cachedInfo ? JSON.parse(cachedInfo) : {};
        } catch (error) {
            LoginStatus.loginInfo = {};
        }
    }

    toLog("----->getUserInfo:")
    toLog(LoginStatus.loginInfo);

    return LoginStatus.loginInfo;
};


/**
 * 将用户信息存入缓存
 */
export const setUserInfo = (userInfo) => {
    // 确保传入的 userInfo 是一个对象，如果未传值则存储空对象
    LoginStatus.loginInfo = userInfo && typeof userInfo === "object" ? userInfo : {};

    try {
        const queryKey = (KEY_USER_INFO + "_" + DefaultLang.region).toUpperCase();
        localStorage.setItem(queryKey, JSON.stringify(LoginStatus.loginInfo));
    } catch (error) {
        toLog(error);
    }

    toLog("----->setUserInfo:")
    toLog(LoginStatus.loginInfo);
};


/**
 * 删除本地缓存中的 用户 信息
 */
export const removeUserInfo = () => {
    const queryKey = (KEY_USER_INFO + "_" + DefaultLang.region).toUpperCase();
    LoginStatus.loginInfo = null
    localStorage.removeItem(queryKey)
}

/**
 * 判断本地缓存中是否存在 用户 信息
 */
export const isLogin = () => {
    return !isEmptyString(getUserInfo().token)
}


/**
 * 从本地缓存中获取 设备标识 信息
 * 
 * @returns {String} device id
 */
export const getDeviceIDInfo = () => {
    const deviceIDInfo = localStorage.getItem(KEY_DEVICE_ID_INFO) || ""
    return deviceIDInfo
}

/**
 * 将 设备标识 信息存入缓存
 * @param {String} device id 
 */
export const setDeviceIDInfo = deviceID => {
    try {
        //再保存本地
        localStorage.setItem(KEY_DEVICE_ID_INFO, (deviceID || ""))
    } catch (e) {
    }
}

/**
 * 删除本地缓存中的 device id 信息
 */
export const removeDeviceIDInfo = () => {
    localStorage.removeItem(KEY_DEVICE_ID_INFO)
}


/**
 * 将 宗教 信息存入缓存
 * @param {String} device id 
 */
export const setReligionInfo = value => {
    try {
        localStorage.setItem(KEY_RELIGION_CLERE, (value || ""))
    } catch (e) {
    }
}

/**
 * 从本地缓存中获取 宗教 信息
 * 
 * @returns {String} device id
 */
export const getReligionInfo = () => {
    const info = localStorage.getItem(KEY_RELIGION_CLERE) || ""
    return info
}

/**
 * 将 订单号 信息存入缓存
 * @param {String} order id 
 */
export const setOrderInfo = orderId => {
    try {
        //再保存本地
        localStorage.setItem(KEY_ORDER_ID, (orderId || ""))
    } catch (e) {
    }
}

/**
 * 从本地缓存中获取 订单号 信息
 * 
 * @returns {String} order id
 */
export const getOrderIdInfo = () => {
    const orderId = localStorage.getItem(KEY_ORDER_ID) || ""
    return orderId
}


/**
 * 从本地缓存中获取 aduid 信息
 * 
 * @returns {String} aduid
 */
export const getAduidInfo = () => {
    const aduidInfo = localStorage.getItem(KEY_ADUID_INFO) || ""
    return aduidInfo
}

/**
 * 将 aduid 信息存入缓存
 * @param {String} aduid  
 */
export const setAduidInfo = aduid => {
    try {
        localStorage.setItem(KEY_ADUID_INFO, (aduid || ""))
    } catch (e) {
    }
}

/**
 * 删除本地缓存中的 aduid 信息
 */
export const removeAduidInfo = () => {
    localStorage.removeItem(KEY_ADUID_INFO)
}

/**
 * 将 advertId 信息存入缓存
 * @param {String} advertId  
 */
export const setAdvertIdInfo = advertId => {
    try {
        localStorage.setItem(KEY_ADVERT_ID_INFO, (advertId || ""))
    } catch (e) {
    }
}

/**
 * 从本地缓存中获取 advertId 信息
 * 
 * @returns {String} advertId
 */
export const getAdvertIdInfo = () => {
    const advertId = localStorage.getItem(KEY_ADVERT_ID_INFO) || ""
    return advertId
}

/**
 * 将 chat mobile 信息存入缓存
 */
export const setChatMobile = (mobile) => {
    try {
        localStorage.setItem(KEY_CHAT_MOBILE, mobile || "")
    } catch (e) {
    }
}

/** 
 * 从本地缓存中获取 chat mobile 信息
 * 
 * @returns {String} mobile
 */
export const getChatMobile = () => {
    const mobile = localStorage.getItem(KEY_CHAT_MOBILE) || ""
    return mobile
}

/**
 * 将 app review 信息存入缓存
 */
export const setAppReviewFlag = () => {
    try {
        localStorage.setItem(KEY_APP_REVIEW, "YES")
    } catch (e) {
    }
}

/**
 * 从本地缓存中获取 app review 信息
 * 
 * @returns {String} appReviewFlag
 */
export const getAppReviewFlag = () => {
    const appReviewFlag = localStorage.getItem(KEY_APP_REVIEW) || ""
    return appReviewFlag
}

/**
 * 将 入口链接 信息存入缓存
 * @param {String} adSourceUrl 从后端获取到的 入口链接 信息
 */
export const setAdSourceUrlInfo = adSourceUrl => {
    try {
        //再保存本地
        localStorage.setItem(KEY_AD_SOURCE_URL, adSourceUrl || "")
    } catch (e) {
    }
}

/**
 * 获取 入口链接 信息
 * @returns {String} adSourceUrl 从后端获取到的 入口链接 信息
 */
export const getAdSourceUrlInfo = () => {
    const adSourceUrl = localStorage.getItem(KEY_AD_SOURCE_URL) || "";
    return adSourceUrl;
}


/**
 * 保存登录mobile
 * @returns {String} value
 */
export const setLoginMobile = value => {
    try {
        const queryKey = (KEY_LOGIN_MOBILE_CLERE + "_" + DefaultLang.region).toUpperCase();
        localStorage.setItem(queryKey, value || "")
    } catch (e) {
    }
}

/**
 * 获取 登录mobile 信息
 * @returns {String} value
 */
export const getLoginMobile = () => {
    const queryKey = (KEY_LOGIN_MOBILE_CLERE + "_" + DefaultLang.region).toUpperCase();
    const value = localStorage.getItem(queryKey) || "";
    return value;
}


/**
 * 保存 产品token
 * @returns {String} value
 */
export const setProductToken = value => {
    try {
        const queryKey = ("KEY_PRODUCT_TK_CLERE_" + DefaultLang.region).toUpperCase();
        localStorage.setItem(queryKey, value || "")
    } catch (e) {
    }
}

/**
 * 获取 产品token 信息
 * @returns {String} value
 */
export const getProductToken = () => {
    const queryKey = ("KEY_PRODUCT_TK_CLERE_" + DefaultLang.region).toUpperCase();
    const value = localStorage.getItem(queryKey) || "";
    return value;
}


/**
 * 保存 产品name
 * @returns {String} value
 */
export const setProductName = value => {
    try {
        const queryKey = ("KEY_PRODUCT_NAME_CLERE_" + DefaultLang.region).toUpperCase();
        localStorage.setItem(queryKey, value || "")
    } catch (e) {
    }
}

/**
 * 获取 产品name 信息
 * @returns {String} value
 */
export const getProductName = () => {
    const queryKey = ("KEY_PRODUCT_NAME_CLERE_" + DefaultLang.region).toUpperCase();
    const value = localStorage.getItem(queryKey) || "";
    return value;
}

/**
 * 保存 用户name
 * @returns {String} value
 */
export const setUserName = value => {
    try {
        LoginStatus.fullName = value || "";
        const queryKey = ("KEY_USER_NAME_CLERE_" + DefaultLang.region).toUpperCase();
        localStorage.setItem(queryKey, value || "")
    } catch (e) {
    }
}

/**
 * 获取 用户name 信息
 * @returns {String} value
 */
export const getUserName = () => {
    if (!LoginStatus.fullName || LoginStatus.fullName.length === 0) {
        const queryKey = ("KEY_USER_NAME_CLERE_" + DefaultLang.region).toUpperCase();
        LoginStatus.fullName = localStorage.getItem(queryKey) || "";
    }
    return LoginStatus.fullName;
}

/**
 * 保存 订单id
 * @returns {String} value
 */
export const setPinOrderId = value => {
    try {
        const queryKey = ("KEY_PIN_ORDER_ID_CLERE_" + DefaultLang.region).toUpperCase();
        localStorage.setItem(queryKey, value || "")
    } catch (e) {
    }
}

/**
 * 获取 订单id 信息
 * @returns {String} value
 */
export const getPinOrderId = () => {
    const queryKey = ("KEY_PIN_ORDER_ID_CLERE_" + DefaultLang.region).toUpperCase();
    const value = localStorage.getItem(queryKey) || "";
    return value;
}

/**
 * 保存 头像编号
 * @returns {String} value
 */
export const setAvatarId = value => {
    try {
        const queryKey = ("KEY_AVATAR_ID_CLERE_" + DefaultLang.region).toUpperCase();
        localStorage.setItem(queryKey, value || "0")
    } catch (e) {
    }
}

/**
 * 获取 头像编号 信息
 * @returns {String} value
 */
export const getAvatarId = () => {
    const queryKey = ("KEY_AVATAR_ID_CLERE_" + DefaultLang.region).toUpperCase();
    const value = localStorage.getItem(queryKey) || "0";
    return value;
}

/**
 * 保存 好用户号码
 * @returns {String} value
 */
export const setGoodUserMobile = value => {
    try {
        const queryKey = ("KEY_GOOD_USER_MOBILE_CLERE_" + DefaultLang.region).toUpperCase();
        localStorage.setItem(queryKey, value || "")
    } catch (e) {
    }
}

/**
 * 获取 好用户号码 信息
 * @returns {String} value
 */
export const getGoodUserMobile = () => {
    const queryKey = ("KEY_GOOD_USER_MOBILE_CLERE_" + DefaultLang.region).toUpperCase();
    const value = localStorage.getItem(queryKey) || "";
    return value;
}

/**
 * 保存 好用户-订单跟踪 当前倒计时数
 * @returns {String} value
 */
export const setGoodUserTrackerSeconds = value => {
    try {
        let maxValue = 1800;
        try {
            const result = parseInt(value);
            maxValue = Math.min(result, 1800);
        } catch (error) {
            // 
            maxValue = 1800;
        }

        const queryKey = ("KEY_GOOD_USER_TRACKER_SEC_CLERE_" + DefaultLang.region).toUpperCase();
        localStorage.setItem(queryKey, String(maxValue) || "1800")
    } catch (e) {
    }
}

/**
 * 获取 好用户-订单跟踪 当前倒计时数
 * @returns {String} value
 */
export const getGoodUserTrackerSeconds = () => {
    const queryKey = ("KEY_GOOD_USER_TRACKER_SEC_CLERE_" + DefaultLang.region).toUpperCase();
    const value = localStorage.getItem(queryKey) || "1800";
    try {
        const result = parseInt(value);
        return Math.min(result, 1800);
    } catch (error) {
        // 
        return 1800
    }
}