
import { ApplyStatus } from "./applyStatus"
import { OrderStatus } from "./orderStatus"
// import { getUserInfo } from "./storage"

/**
 * 判断订单是否在等待签约中
 * @param {String} applyStatus
 */
export const isLoanSigning = (applyStatus, signOrderId) => {
    if (applyStatus) {
        const signFlag = (applyStatus.toUpperCase() === "YES")
        const hadOrderId = ((signOrderId || "").length > 0)

        //缓存用户信息
        const isTestAcc = true
        // const userInfo = getUserInfo()
        // const mobile = userInfo.mobile || ""
        // const isTestAcc = (mobile === "9162222222")

        return (signFlag && hadOrderId && isTestAcc)
    }
    return false
}

/**
 * 判断订单是否在审核中
 * @param {String} applyStatus
 */
export const isLoanReviewing = (applyStatus) => {
    switch (applyStatus) {
        case ApplyStatus.CREATED: return true
        case ApplyStatus.SYS_CHECK: return true
        case ApplyStatus.SYS_APROVAL: return true
        case ApplyStatus.MANU_APROVAL: return true
        case ApplyStatus.DS_APROVAL: return true
        default: return false
    }
}

/**
 * 判断订单是否在放款中
 * @param {String} orderStatus
 */
export const isLoanLoaning = (orderStatus) => {
    switch (orderStatus) {
        case OrderStatus.CREATED: return true
        case OrderStatus.LENDING: return true
        default: return false
    }
}

/**
 * 判断订单是否在还款中
 * @param {String} orderStatus
 */
export const isLoanRepayNow = (orderStatus) => {
    switch (orderStatus) {
        case OrderStatus.LOANED: {
            return true
        }
        case OrderStatus.OVERDUE: {
            return true
        }
        case OrderStatus.BAD_DEBT: {
            return true
        }
        default: return false
    }
}

/**
 * 判断订单是否被拒绝
 * @param {String} applyStatus
 * @param {String} orderStatus
 */
export const isLoanReject = (applyStatus, orderStatus) => {
    const applyReject = (
        applyStatus === ApplyStatus.SYS_DENIED ||
        applyStatus === ApplyStatus.MANU_DENIED ||
        applyStatus === ApplyStatus.DS_DENIED)

    const orderReject = (orderStatus === OrderStatus.DENIED)

    return (applyReject || orderReject)
}