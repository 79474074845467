import { toLog } from "./utils/utils";

export default class DefaultLang {
    static languageEn = "en";
    static languageFr = "fr";
    static languageSw = "sw";

    static languageLabelSw = "kiswahili";
    static languageLabelFr = "Français";
    static languageLabelEn = "English";

    static region = "gh";
    static catalog = "website";
    static children = "";
    static language = DefaultLang.languageEn;
    static languageLabel = "English";
    static country = "ghana";
    static phoneArea = "+233";

    static countryData = {
        gh: { region: "gh", country: "ghana", phoneArea: "+233", tid: "Africa/Accra", language: "en", languageLabel: "English" },
        zm: { region: "zm", country: "zambia", phoneArea: "+260", tid: "Africa/Lusaka", language: "en", languageLabel: "English" },
        ug: { region: "ug", country: "uganda", phoneArea: "+256", tid: "Africa/Kampala", language: "en", languageLabel: "English" },
        ke: { region: "ke", country: "kenya", phoneArea: "+254", tid: "Africa/Nairobi", language: "en", languageLabel: "English" },
        ci: { region: "ci", country: "cote", phoneArea: "+225", tid: "Africa/Abidjan", language: "fr", languageLabel: "Français" },
        sn: { region: "sn", country: "senegal", phoneArea: "+221", tid: "Africa/Dakar", language: "fr", languageLabel: "Français" },
        cm: { region: "cm", country: "cameroon", phoneArea: "+237", tid: "Africa/Douala", language: "fr", languageLabel: "Français" },
        bn: { region: "bn", country: "benin", phoneArea: "+229", tid: "Africa/Porto-Novo", language: "fr", languageLabel: "Français" },
        cg: { region: "cg", country: "congo", phoneArea: "+243", tid: "Africa/Lubumbashi", language: "fr", languageLabel: "Français" },
        tza: { region: "tza", country: "tanzania", phoneArea: "+255", tid: "Africa/Dar_es_Salaam", language: "sw", languageLabel: "kiswahili" }
    };

    static init() {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const matchedCountry = Object.values(DefaultLang.countryData).find(country =>
            timeZone.includes(country.tid)
        ) || DefaultLang.countryData.gh;

        DefaultLang.region = matchedCountry.region;
        DefaultLang.country = matchedCountry.country;
        DefaultLang.phoneArea = matchedCountry.phoneArea;
        DefaultLang.language = matchedCountry.language;
        DefaultLang.languageLabel = matchedCountry.languageLabel;
    }

    static setCountry(region) {
        const countryInfo = DefaultLang.countryData[region];
        if (countryInfo) {
            DefaultLang.country = countryInfo.country;
            DefaultLang.phoneArea = countryInfo.phoneArea;
            DefaultLang.region = region;
            DefaultLang.language = countryInfo.language;
            DefaultLang.languageLabel = countryInfo.languageLabel;

        } else {
            DefaultLang.init();
        }
    }

    static setCatalog(catalog) {
        DefaultLang.catalog = catalog?.trim() || "website";

        if (DefaultLang.catalog === "/" || DefaultLang.catalog === "//") {
            DefaultLang.catalog = "website";
        }
    }

    static setChildren(children) {
        DefaultLang.children = children?.trim() || "";
    }

    static getApiProduct() {
        const input = DefaultLang.catalog;

        if (input.startsWith("h5market")) {
            return "CLERECASH";
        }
        if (input.startsWith("website")) {
            return "CLERECASH";
        }
        if (input.startsWith("landing")) {
            return "CLERECASH";
        }

        return input.startsWith("h5")
            ? input.slice(2).toUpperCase()
            : input.toUpperCase();
    }

    static getApiPath() {
        const input = DefaultLang.catalog;

        if (input.startsWith("h5market")) {
            return "/front";
        }
        if (input.startsWith("website")) {
            return "/front";
        }
        if (input.startsWith("landing")) {
            return "/front";
        }

        const path = input.startsWith("h5")
            ? input.slice(2).toLowerCase()
            : input.toLowerCase();

        const regionSuffixMap = {
            Ghana: "gha",
            Zambia: "zmb",
            Uganda: "ugx",
            Kenya: "ksh",
            Cotediva: "fcfa",
            Senegal: "senegal",
            Cameroon: "xaf",
            Tanzania: "tanz",
            Benin: "benin",
            Congo: "congo"
        };

        for (const [region, suffix] of Object.entries(regionSuffixMap)) {
            if (DefaultLang[`is${region}`]()) {
                return `/${path}${suffix}`;
            }
        }

        return `/${path}`;
    }

    static detectLanguage = () => {
        // https://www.com

        const location = window.location.href;
        const pathParts = location.split('/');

        const regionIndex = 3;
        const region = pathParts.length > regionIndex ? pathParts[regionIndex] : null;
        DefaultLang.setCountry(region);

        const catalogIndex = regionIndex + 1;
        const catalog = pathParts.length > catalogIndex ? pathParts[catalogIndex] : null;
        DefaultLang.setCatalog(catalog);

        const landingFlag = "landing";
        if (region.startsWith(landingFlag)) {
            DefaultLang.setCatalog(landingFlag);
        }

        const childrenIndex = catalogIndex + 1;
        let children = "";
        for (let index = childrenIndex; index < pathParts.length; index++) {
            const path = pathParts[index];
            children += ("/" + path);
        }
        DefaultLang.setChildren(children);

        return DefaultLang.language;
    }

    static isNeedPwaInstall() {

        if (DefaultLang.catalog.startsWith('h5market')) {
            const isSpecialPath =
                DefaultLang.children.startsWith('/gooduser') ||
                DefaultLang.children.startsWith('/landing');

            if (isSpecialPath) {
                return false;
            }

            const isInstalledPWA =
                window.matchMedia('(display-mode: window-controls-overlay)').matches ||
                window.matchMedia('(display-mode: standalone)').matches;

            return !isInstalledPWA;
        }

        return false;
    }

    static isSwZone = () => {
        return (DefaultLang.isTanzania());
    }

    static isFrZone = () => {
        return (DefaultLang.isCotediva()) || (DefaultLang.isSenegal()) || (DefaultLang.isCameroon()) || (DefaultLang.isBenin()) || (DefaultLang.isCongo());
    }

    static isEnZone = () => {
        return (DefaultLang.isGhana()) || (DefaultLang.isZambia()) || (DefaultLang.isUganda()) || (DefaultLang.isKenya());
    }

    static isGhana = () => {
        return DefaultLang.region === DefaultLang.countryData.gh.region;
    }

    static isZambia = () => {
        return DefaultLang.region === DefaultLang.countryData.zm.region;
    }

    static isUganda = () => {
        return DefaultLang.region === DefaultLang.countryData.ug.region;
    }

    static isKenya = () => {
        return DefaultLang.region === DefaultLang.countryData.ke.region;
    }

    static isCotediva = () => {
        return DefaultLang.region === DefaultLang.countryData.ci.region;
    }

    static isSenegal = () => {
        return DefaultLang.region === DefaultLang.countryData.sn.region;
    }

    static isCameroon = () => {
        return DefaultLang.region === DefaultLang.countryData.cm.region;
    }

    static isBenin = () => {
        return DefaultLang.region === DefaultLang.countryData.bn.region;
    }

    static isCongo = () => {
        return DefaultLang.region === DefaultLang.countryData.cg.region;
    }

    static isTanzania = () => {
        return DefaultLang.region === DefaultLang.countryData.tza.region;
    }

    static printLog() {
        toLog(`->region: ${DefaultLang.region}`);
        toLog(`->catalog: ${DefaultLang.catalog}`);
        toLog(`->children: ${DefaultLang.children}`);
        toLog(`->country: ${DefaultLang.country}`);
        toLog(`->language: ${DefaultLang.language}`);
        toLog(`->languageLabel: ${DefaultLang.languageLabel}`);
        toLog(`->phoneArea: ${DefaultLang.phoneArea}`);
    }
}
