// import { getAnalytics, getGoogleAnalyticsClientId, logEvent } from "firebase/analytics";
// import { initializeApp } from "firebase/app";


export const initFirebaseSDK = async () => {
    // const firebaseConfig = {
    //     apiKey: "AIzaSyA19nPIPq-Ywr9X-GI4QfUuHRT2i1DgYEs",
    //     authDomain: "test-240626.firebaseapp.com",
    //     projectId: "test-240626",
    //     storageBucket: "test-240626.appspot.com",
    //     messagingSenderId: "325851074040",
    //     appId: "1:325851074040:web:15ca71f462a4063c89da0d",
    //     measurementId: "G-RR6E66MEMY"
    // };
    // initializeApp(firebaseConfig);

    // // 862575376.1720579955
    // getAnalyticsClientId();
}

export const getAnalyticsClientId = async () => {
    // const analytics = getAnalytics();
    // const clientId = await getGoogleAnalyticsClientId(analytics);
    // window.firebaseClientID = clientId;
}


export const addPwaInstalledEvent = async () => {
    // const analytics = getAnalytics();
    // logEvent(analytics, 'pwa_installed');
}

export const addRegisterEvent = async () => {
    // const analytics = getAnalytics();
    // logEvent(analytics, 'sign_up', {
    //     method: "clerecash"
    // });

    // try {
    //     // eslint-disable-next-line no-undef
    //     gtag('event', 'sign_up', {
    //         // <event_parameters>
    //     });
    // } catch (e) { }
}

export const addHomeNextEvent = async () => {
    // const analytics = getAnalytics();
    // logEvent(analytics, 'home_next');
}

export const addProofStepDoneEvent = async (pageTag) => {
    // const analytics = getAnalytics();
    // logEvent(analytics, pageTag);
}

export const addPurchseEvent = async (orderId) => {
    // const analytics = getAnalytics();
    // logEvent(analytics, 'purchase', {
    //     currency: "USD",
    //     value: "1",
    //     transaction_id: orderId || "",
    //     items: [{ item_id: orderId, item_name: "clerecash" }]
    // });
}

export const addPaymentEvent = async () => {
    // const analytics = getAnalytics();
    // logEvent(analytics, 'invoke_payment');
}