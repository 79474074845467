// AsToast.js
import ReactDOM from 'react-dom/client';
import styles from './asToast.module.css';

import pic_toast_icon from "@/assets/pic_toast_icon.webp"

class AsToast {
    static instance = null;

    static show(message) {
        document.body.style.overflow = 'hidden';

        if (!AsToast.instance) {
            const toastContainer = document.createElement('div');
            toastContainer.id = 'as-toast-container';
            document.body.appendChild(toastContainer);
            AsToast.instance = ReactDOM.createRoot(toastContainer);
        }

        AsToast.instance.render(
            <div className={styles.asToastOverlay}>
                <div className={styles.asToastContent}>
                    <img className={styles.asToastIcon} src={pic_toast_icon} alt='' />
                    <span className={styles.asToastContentText}>{message}</span>
                </div>
            </div>
        );

        // 自动隐藏 Toast 提示
        setTimeout(() => {
            AsToast.hide();
        }, 2000);
    }

    static hide() {
        document.body.style.overflow = '';

        if (AsToast.instance) {
            AsToast.instance.unmount();
            AsToast.instance = null;
        }
    }
}

export default AsToast;
