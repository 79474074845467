/**
 * 登录状态类
 */
export default class LoginStatus {
    /**
     * 转发路径 
     */
    static zoneInfo = null;

    /**
     * 登录信息
     */
    static loginInfo = null;

    /**
     * 用户名
     */
    static fullName = null;
}