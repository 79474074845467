import { applyMiddleware, legacy_createStore as createStore } from "redux";
import reduxThunk from 'redux-thunk'
import reducers from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

// const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
const enhancer = composeWithDevTools(applyMiddleware(reduxThunk));

// 创建 Store 实例
const store = createStore(
  reducers,

  // 参数二：初始化时要加载的状态
  {
    appConfig: {}
  },

  // 参数三：加强器
  enhancer
)

// 导出 Store 实例
export default store