// AsOpenFail.js
import ReactDOM from 'react-dom/client';
import '../../../../../globalShape.css';
import '../../../../../globalText.css';
import '../../../../../globalMargin.css';
import '../../../../../globalLayout.css';
import '../../../../../globalPop.css';

import { useTranslation } from 'react-i18next';

import pic_good_install_flow_diagram_not_open from "@/assets/pic_good_install_flow_diagram_not_open.webp"
import pic_good_install_right_arrow from "@/assets/pic_good_install_right_arrow.webp"
import DefaultLang from '@/useLanguage';
import { copyLabel } from '@/utils/utils';


const AsOpenFailContent = () => {

    const { t } = useTranslation();

    const onHandleCloseClick = () => {
        let url = process.env.REACT_APP_INSTALL_URL + DefaultLang.region + "/h5market/";
        copyLabel(url);
        // window.open(url, "_blank");
        // AsOpenFail.hide();
    }

    return (
        <div id="as-open-fail-container-overlay" className={`popOverlay popOverlayCenter popFadeIn`} onClick={() => { }}>
            <div className={`popContentBg`} onClick={(e) => { e.stopPropagation(); }}>
                <div className={`popContent paddingH16`}>

                    <div className={`layoutRowCenterW100 topMargin16`}>
                        <img className='layoutIconSize30' src={pic_good_install_flow_diagram_not_open} alt='' loading='lazy' />
                    </div>

                    <div className={`layoutRowCenterW100 topMargin14`}>
                        <span className={`textNormal textSize14 textColorF22424`}>{t('openFailDialog1')}</span>
                    </div>

                    <div className={`layoutRowCenterW100 topMargin16`}>
                        <span className={`textNormal textSize14 textColorBlack textAlignCenter`}>{t('openFailDialog2')}</span>
                    </div>

                    <div className='layoutRowCenterW100 paddingH20'>
                        <div className="layoutRowCenterW100 layoutHeight50 shapeR25 shapeBlue topMargin36" onClick={() => { }}>
                            <span className="textBold textSize16 textColorWhite">{t('openFailDialog3')}</span>
                            <img className="layoutIconSize20 leftMargin10" src={pic_good_install_right_arrow} alt="" loading="lazy" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

class AsOpenFail {
    static instance = null;

    static show() {

        document.body.style.overflow = 'hidden';

        if (!AsOpenFail.instance) {
            const toastContainer = document.createElement('div');
            toastContainer.id = 'as-open-fail-container';
            document.body.appendChild(toastContainer);
            AsOpenFail.instance = ReactDOM.createRoot(toastContainer);
        }

        AsOpenFail.instance.render(<AsOpenFailContent />);
    }

    static hide() {
        document.body.style.overflow = '';

        if (AsOpenFail.instance) {
            const container = document.getElementById('as-open-fail-container-overlay');
            if (container) {
                container.classList.add(`popFadeOut`);
                container.addEventListener('animationend', () => {
                    AsOpenFail.instance.unmount();
                    AsOpenFail.instance = null;
                    container.remove();
                }, { once: true });
            }
        }
    }
}

export default AsOpenFail;
