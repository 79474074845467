import axios from "axios";
import { getAdSourceUrlInfo, getUserInfo } from "./storage";
import { checkOSType, toLog } from "./utils";
import { BaseUrl } from "./apiUrl";
import { generateAudidV2 } from "./aduid";
import AppParams from "./appParams";
import DefaultLang from "@/useLanguage";
import DeviceID, { generateDeviceIdV3 } from "./deviceId";

const http = axios.create({
    baseURL: BaseUrl,
    timeout: 50000,
    withCredentials: true,
    responseType: 'json',
    headers: {
        // 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
})

http.interceptors.request.use(config => {
    toLog(config.data)
    return config
})

http.interceptors.response.use(response => {
    toLog(response.data)
    return response.data;
}, err => {
    return { code: "0", data: {}, obj: {}, msg: "Request Error" };
})

export default http

export const baseHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'country': DefaultLang.country
        }
    }
};

export const baseHeadersGet = () => {
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'country': DefaultLang.country
        }
    };
};

export const baseParams = () => {
    const userInfo = getUserInfo();
    const aduid = generateAudidV2();

    const deviceId = DeviceID.getVisitorId();
    const deviceIdV2 = generateDeviceIdV3(deviceId);
    const adSourceUrl = getAdSourceUrlInfo();

    // let channelCode = AppParams.referrer || ""
    // if (channelCode.length === 0) {
    //     channelCode = "HFIVESIGN"
    // }
    const channelCode = process.env.REACT_APP_CHANNEL_CODE + "";
    const h5Version = process.env.REACT_APP_VERSION + "";

    const params = {}
    params.token = userInfo.token || "";
    params.userId = userInfo.userId || "";
    params.clientType = checkOSType();
    params.androidId = deviceIdV2;
    params.advertId = deviceIdV2;
    params.aduid = aduid;
    params.aduidH5 = deviceIdV2;
    params.aduidPath = navigator.userAgent;
    params.locationUrl = AppParams.urlParams;
    params.h5ApplyFlag = "YES";
    params.product = DefaultLang.getApiProduct();

    params.channelCode = channelCode;
    params.subChannelCode = channelCode;
    params.version = h5Version;
    params.apiVersion = "v3";
    params.gtgClientId = window.gTagClientID || "";
    params.firebaseClientId = window.firebaseClientID || "";
    params.adSourceUrl = adSourceUrl;

    return params
}
