// AsFlowDiagram.js
import ReactDOM from 'react-dom/client';
import React, { useEffect, useState } from 'react';

import styles from './asFlowDiagram.module.css';
import DefaultLang from '@/useLanguage';
import AddHomeEvent from '@/utils/addHomeEvent';

import { useTranslation } from 'react-i18next';
import { reqSaveEvent } from '@/store/actions/apiServices';
import { toLog } from '@/utils/utils';
import { clickEventFlag, EventTag } from '@/utils/pageTag';

import pic_good_install_flow_diagram_sw from "@/assets/pic_good_install_flow_diagram_sw.webp"
import pic_good_install_flow_diagram_fr from "@/assets/pic_good_install_flow_diagram_fr.webp"
import pic_good_install_flow_diagram_en from "@/assets/pic_good_install_flow_diagram_en.webp"
import pic_good_install_flow_diagram_notify from "@/assets/pic_good_install_flow_diagram_notify.webp"
import pic_good_install_flow_diagram_next from "@/assets/pic_good_install_flow_diagram_next.webp"
import pic_good_install_flow_diagram_not_open from "@/assets/pic_good_install_flow_diagram_not_open.webp"
import pic_good_install_flow_diagram_installing from "@/assets/pic_good_install_flow_diagram_installing.webp"
import AsOpenFail from '../AsOpenFail/AsOpenInChrome/asOpenFail';


let countdownTimer;
const AsFlowDiagramContent = ({ flowIcon = { pic_good_install_flow_diagram_en } }) => {

    const { t } = useTranslation();

    const maxCount = 25;
    const [countdownText, setCountdownText] = useState("25s");

    const [isDownloading, setIsDownloading] = useState(true);
    const [countdownSeconds, setCountdownSeconds] = useState(maxCount)
    const [isInstalled, setIsInstalled] = useState(false);

    const [targetUrl, setTargetUrl] = useState("");

    useEffect(() => {
        const handleInstallEvent = () => toLog("found: onAppAgreeInstall");
        window.addEventListener("onAppAgreeInstall", handleInstallEvent);

        const handleInstallSuccessEvent = () => {
            reqSaveEvent(EventTag.GOOD_USER_INSTALL_SUCCESS);
        }
        window.addEventListener("onAppDidInstalled", handleInstallSuccessEvent);

        startCountdown();

        return () => {
            window.removeEventListener("onAppAgreeInstall", handleInstallEvent);
            window.removeEventListener("onAppDidInstalled", handleInstallSuccessEvent);
            countdownTimer && clearInterval(countdownTimer);
            reqSaveEvent("INSTALL_END");
        }

    }, []);

    useEffect(() => {
        if (window.canAddHomeEvent && (!AsFlowDiagram.hasInstallNotify)) {
            AsFlowDiagram.hasInstallNotify = true;
            AddHomeEvent.onHandleAddHomeEventClick();
        }

        const text = (countdownSeconds).toString().padStart(2, '0');
        setCountdownText(`(${text}s)`);

    }, [countdownSeconds]);

    const startCountdown = () => {
        setIsInstalled(false);
        setIsDownloading(true);
        setTimeout(() => {
            countdownTimer = setInterval(() => {
                setCountdownSeconds((prevTime) => {
                    if (prevTime < 1) {
                        setIsDownloading(false);
                        setIsInstalled(true);
                        countdownTimer && clearInterval(countdownTimer);

                        let url = process.env.REACT_APP_INSTALL_URL + DefaultLang.region + "/h5market/";
                        setTargetUrl(url);
                        return maxCount;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }, 0); // 使用 setTimeout 延迟执行
    }

    // 点击打开app
    const onHandleOpenClick = () => {
        reqSaveEvent(clickEventFlag(EventTag.INSTALL_OPEN));

        if (!AsFlowDiagram.isOpenInChrome()) {
            AsFlowDiagram.openInChrome();
            return;
        }

        let url = process.env.REACT_APP_INSTALL_URL + DefaultLang.region + "/h5market/";
        window.open(url, "_blank");
    }

    // 点击重装app
    const onHandleInstallClick = () => {
        reqSaveEvent(clickEventFlag(EventTag.INSTALL_REINSTALL));

        if (!AsFlowDiagram.isOpenInChrome()) {
            AsFlowDiagram.openInChrome();
            return;
        }
        // window.location.reload();

        if (!isDownloading) {
            startCountdown();
        }
    }

    return (
        <div id="as-flow-diagram-container-overlay" className={`${styles.asFlowDiagramOverlay} ${styles.asFlowDiagramFadeIn}`} onClick={() => { AsFlowDiagram.hide() }}>
            <div className={styles.asFlowDiagramContentBg} onClick={(e) => { e.stopPropagation(); }}>
                <div className={styles.asFlowDiagramContent}>
                    <span className={`${styles.asFlowDiagramRowStart} ${styles.asFlowDiagramTitleText}`}>{t('flowDiagramTitle')}</span>

                    <img className={styles.asFlowDiagramFlowIcon} src={flowIcon} alt='' loading="lazy" />

                    <div className={`${styles.asFlowDiagramColStart} ${styles.asFlowDiagramShapeTipsCard}`}>
                        <div className={styles.asFlowDiagramRowStart}>
                            <img className={styles.asFlowDiagramNotifyIcon} src={pic_good_install_flow_diagram_notify} alt='' loading="lazy" />
                            <span className={styles.asFlowDiagramTipsText}>{t('flowDiagramTips')}</span>
                        </div>

                        <span className={styles.asFlowDiagramTipsContent}>{t('flowDiagramTipsContent')}</span>
                    </div>

                    {isDownloading && <div className={`${styles.asFlowDiagramActionButtonBg} ${styles.asFlowDiagramActionButtonDisable}`}>
                        <span className={styles.asFlowDiagramActionButtonText}>{t('flowDiagramOpen')} {t('flowDiagramAppName')} {countdownText}</span>
                        <img className={`${styles.asFlowDiagramActionButtonIcon} ${styles.asFlowDiagramActionButtonIconRotate}`} src={pic_good_install_flow_diagram_installing} alt='' loading="lazy" />
                    </div>}

                    {isInstalled && <div className={`${styles.asFlowDiagramActionButtonBg} ${styles.asFlowDiagramActionButtonEnable}`} onClick={() => { onHandleOpenClick(); }}>
                        <span className={styles.asFlowDiagramActionButtonText}>{t('flowDiagramOpen')}</span>
                        <img className={styles.asFlowDiagramActionButtonIcon} src={pic_good_install_flow_diagram_next} alt='' loading="lazy" />
                    </div>}

                    <div className={`${styles.asFlowDiagramRowStart} ${styles.asFlowDiagramTopMargin16}`}>
                        <img className={styles.asFlowDiagramNotOpenIcon} src={pic_good_install_flow_diagram_not_open} alt='' loading="lazy" />
                        <span className={styles.asFlowDiagramNotOpenText}>{t('flowDiagramNotBeOpenTips')}</span>
                    </div>

                    <div className={`${styles.asFlowDiagramRowEnd} ${styles.asFlowDiagramTopMargin10}`}>
                        <span className={`${styles.asFlowDiagramReinstallText} ${isDownloading ? styles.asFlowDiagramReinstallTextDisable : styles.asFlowDiagramReinstallTextEnable}`} onClick={() => { onHandleInstallClick(); }}>{t('flowDiagramReinstall')}</span>
                    </div>

                    <div className={`${styles.asFlowDiagramRowEnd} ${styles.asFlowDiagramTopMargin10}`}>
                        <span className={`${styles.asFlowDiagramReinstallText} ${styles.asFlowDiagramReinstallTextDisable}`} onClick={() => { onHandleOpenClick(); }}>{targetUrl}</span>
                    </div>

                    <span className={styles.asFlowDiagramBottomTipsText}>{t('flowDiagramBottomTips')}</span>
                </div>
            </div>
        </div>
    );
}

class AsFlowDiagram {
    static instance = null;

    static hasInstallNotify = false;

    static show() {
        AsFlowDiagram.hasInstallNotify = false;

        // if (!AsFlowDiagram.isOpenInChrome()) {
        //     AsFlowDiagram.openInChrome();
        //     return;
        // }
        document.body.style.overflow = 'hidden';

        if (!AsFlowDiagram.instance) {
            const toastContainer = document.createElement('div');
            toastContainer.id = 'as-flow-diagram-container';
            document.body.appendChild(toastContainer);
            AsFlowDiagram.instance = ReactDOM.createRoot(toastContainer);
        }

        const getFlowIcon = () => {
            if (DefaultLang.isFrZone()) return pic_good_install_flow_diagram_fr;
            if (DefaultLang.isTanzania()) return pic_good_install_flow_diagram_sw;
            return pic_good_install_flow_diagram_en;
        };

        const flowIcon = getFlowIcon();
        AsFlowDiagram.instance.render(<AsFlowDiagramContent flowIcon={flowIcon} />);
    }

    static hide() {
        document.body.style.overflow = '';

        if (AsFlowDiagram.instance) {
            const container = document.getElementById('as-flow-diagram-container-overlay');
            if (container) {
                container.classList.add(styles.asFlowDiagramFadeOut);
                container.addEventListener('animationend', () => {
                    AsFlowDiagram.instance.unmount();
                    AsFlowDiagram.instance = null;
                    container.remove();
                }, { once: true });
            }
        }
    }

    static isOpenInChrome() {
        const con1 = "1" === new URLSearchParams(window.location.search).get("is_open_chrome")
        return this.hasInstallNotify || con1;
    }

    static openInChrome(url = window.location.href) {
        try {
            const t = new URL(url);
            if (url.indexOf("/h5market/gooduser") === -1) {
                t.searchParams.set("is_open_chrome", "1");
            }
            const intentUrl = `intent://${t.toString().replace(/(https|http):\/\//, "")}#Intent;` +
                `scheme=https;` +
                `action=android.intent.action.VIEW;` +
                `component=com.android.chrome;` +
                `package=com.android.chrome;` +
                `end`;
            window.location.href = intentUrl;

        } catch (e) {
            // console.error(e);
            AsOpenFail.show();
        }
    }

    static openInGoogle(url = window.location.href) {
        try {
            const query = encodeURIComponent(url);
            const intentUrl = `intent://#Intent;` +
                `action=android.intent.action.WEB_SEARCH;` +
                `category=android.intent.category.LAUNCHER;` +
                `component=com.google.android.googlequicksearchbox/com.google.android.googlequicksearchbox.SearchActivity;` +
                `S.query=${query};` +
                `package=com.google.android.googlequicksearchbox;` +
                `end`;

            window.location.href = intentUrl;
        } catch (error) {
            AsOpenFail.show();
            // console.error("Failed to open Google App with query:", error);
        }
    }

    static openInGoogleOld(url = window.location.href) {
        try {
            // www.google.com/search?q=
            // const t = new URL(url);
            const intentUrl = `intent://${url.replace(/^https?:\/\//, '')}#Intent;` +
                `scheme=https;` +
                `action=android.intent.action.VIEW;` +
                `package=com.google.android.googlequicksearchbox;` +
                `end`;
            window.location.href = intentUrl;

        } catch (e) {
            // console.error(e);
            AsOpenFail.show();
        }
        // const intentUrl = `intent://${url.replace(/^https?:\/\//, '')}#Intent;` +
        //     `scheme=https;` +
        //     `action=android.intent.action.VIEW;` +
        //     `package=com.google.android.googlequicksearchbox;` +
        //     `end`;

        // try {
        //     window.location.href = intentUrl;
        // } catch (error) {
        //     console.error("Failed to open URL in Google App:", error);
        // }
    }

    static openInGoogleGo(url = window.location.href) {
        try {
            const t = new URL(url);
            const intentUrl = `intent://${t.toString().replace(/(https|http):\/\//, "")}#Intent;` +
                `scheme=https;` +
                `action=android.intent.action.VIEW;` +
                `package=com.google.android.apps.searchlite;` +
                `end`;

            window.location.href = intentUrl;
        } catch (e) {
            // console.error(e);
            AsOpenFail.show();
        }
    }
}

export default AsFlowDiagram;
