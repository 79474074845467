import pic_proof_basic from "@/assets/pic_proof_basic.webp"
import pic_proof_work from "@/assets/pic_proof_work.webp"
import pic_proof_wallet from "@/assets/pic_proof_wallet.webp"
import pic_proof_contact from "@/assets/pic_proof_contact.webp"
import http, { baseHeaders, baseHeadersGet, baseParams } from "@/utils/request";
import i18n from '../../i18n';
import AsLoading from "@/components/AsLoading/asLoading";
import AppConfig from "@/utils/appConfig";
import uploadHttp, { baseUploadHeaders } from "@/utils/uploadRequest";
import { UrlBindBankAccount, UrlConfirmLoanApply, UrlDeleteBoundBankAccount, UrlFetchBoundBankAccount, UrlGetAppConfig, UrlGetOtp, UrlGetReapyDetail, UrlGetReapyUrl, UrlGetUseState, UrlLogin, UrlSaveEvent, UrlSaveFaceInfo, UrlSaveOcrInfo, UrlSaveUserLocation, UrlUploadImage, UrlWebPushSave, toFullApi, UrlSaveDeviceInfo, UrlQueryNewStyleProducts, UrlGetLoanDetailPageForDays, UrlOneKeyCopyToApply, UrlGetLoanDetail, UrlGetBindCardList, UrlUserDeniedDc, UrlQueryPaymentStatus, UrlFeedback, UrlUserLoginNoCode, UrlBindBankAccountSmsCode, UrlGetSimpleData } from "@/utils/apiUrl";
import { daysDifference, isEmptyString, isJSONText, removeTrailingAsterisk, toEllipsisMobile, toEventTagString, toList, toLog, toPercentageString } from "@/utils/utils";
import { endPageFlag, EventTag, PageTag } from "@/utils/pageTag";
import { getLoginMobile, getPinOrderId, getProductName, getProductToken, setChatMobile, setGoodUserMobile, setPinOrderId, setProductName, setProductToken, setUserInfo, setUserName } from "@/utils/storage";
import { isActionSelect, isAllStepsFinish, isProofBankAccount, isProofBankAccountOfFrZone, isProofContact, isProofFace, isProofFrequentlyUsedApps, isProofMobile, isProofOcr, isProofOtherLoans, isProofWork } from "./userState";
import { addProofStepDoneEvent, addPurchseEvent, addRegisterEvent } from "./firebaseHelper";
import { isLoanLoaning, isLoanReject, isLoanRepayNow, isLoanReviewing } from "@/utils/loanStatus";
import { ApplyStatus } from "@/utils/applyStatus";
import { addRegion } from "@/RegionRedirect";
import AsToast from "@/components/AsToast/asToast";


const isUserNotExist = (msg) => {
    if (!msg || msg.length === 0) return false;

    return (msg.indexOf("User does not exist") !== -1) || (msg.indexOf("L'utilisateur n'existe pas") !== -1) || (msg.indexOf("please login again") !== -1) || (msg.indexOf("Mtumiaji hayupo") !== -1);
}

export const reqSaveEvent = async (type, remark = "") => {
    const params = {
        ...baseParams(),
        type: type.startsWith(process.env.REACT_APP_EVENT_PRODUCT_FLAG + "")
            ? toEventTagString(type)
            : process.env.REACT_APP_EVENT_PRODUCT_FLAG + toEventTagString(type),
        remark
    };

    const headers = baseHeaders();

    try {
        await http.post(toFullApi(UrlSaveEvent), params, headers);
        toLog(`reqSaveEvent-end: ======== ${type}`);
    } catch (error) {
        toLog(`reqSaveEvent-error: ${error}`);
    }
};

export const reqSaveTimeEvent = async (type = "", remark = "", isPaste = false, preTime = 0, endTime = null) => {
    if (type.length === 0) return;

    const curTime = endTime ? endTime : new Date().getTime();
    const difTime = (curTime - preTime) + "";
    const pasteFlag = isPaste ? "P" : "E";
    const remarkFormat = remark + "$$" + pasteFlag + "$$" + difTime;
    reqSaveEvent(type, remarkFormat);
}

export const reqCommon = async (url, moreParams, moreHeader, reqCallback, product = null, token = null, showLoading = true, showToast = true) => {
    if (showLoading && showLoading === true) {
        AsLoading.show();
    }

    toLog("reqCommon: " + url);
    toLog("showLoading: " + showLoading);

    //组装参数
    let params = baseParams();
    if (moreParams) {
        params = { ...params, ...moreParams };
    }
    if (product && product.length > 0) {
        params.product = product;
    }
    if (token && token.length > 0) {
        params.token = token;
    }

    let headers = baseHeaders();
    if (moreHeader) {
        headers = { ...headers, ...moreHeader };
    }

    const res = await http.post(toFullApi(url), params, headers);
    const resCode = res.code || "-1";
    const resObj = res.obj
    const msg = res.msg || ""
    const success = (resCode === "0000");
    toLog(`${url}-end:=======`);

    if (showLoading && showLoading === true) {
        AsLoading.hide();
    }

    //如果有错误，提示
    let isNeedToReLogin = (resCode === 401)
    let isNeedToBanned = (resCode === 777) || (resCode === 2001) || isUserNotExist(msg)
    if (!success) {
        if (!isEmptyString(res.msg)) {
            if (showToast === true) {
                AsToast.show(res.msg);
            }
        }
        if (isNeedToBanned) {
            //回调
            reqCallback && reqCallback(false, resObj, false, "");
            setTimeout(() => {
                window.location.replace(addRegion('/login'));
            }, 1000);
            return
        }
        if (isNeedToReLogin) {
            window.location.reload();
            return
        }
    }

    // if (success && resObj) {
    //     window.scrollTo(0, 0);
    // }

    //回调
    reqCallback && reqCallback(success, resObj, msg);
}

export const reqCommonForGet = async (urlFormat, moreParams, moreHeader, reqCallback, product = null, token = null, showLoading = true, showToast = true) => {
    if (showLoading && showLoading === true) {
        AsLoading.show();
    }
    let url = urlFormat + "";
    if (moreParams) {
        const queryString = new URLSearchParams(moreParams).toString();
        url = `${url}?${queryString}`;
    }

    let headers = baseHeadersGet();
    if (moreHeader) {
        headers = { ...headers, ...moreHeader };
    }
    toLog("reqCommonForGet: " + url);
    toLog("showLoading: " + showLoading);

    const res = await http.get(toFullApi(url), headers);
    const resCode = res.code || "-1";
    const resObj = res.obj
    const msg = res.msg || ""
    const success = (resCode === "0000");
    toLog(`${url}-end:=======`);

    if (showLoading && showLoading === true) {
        AsLoading.hide();
    }

    //如果有错误，提示
    let isNeedToReLogin = (resCode === 401)
    let isNeedToBanned = (resCode === 777) || (resCode === 2001) || isUserNotExist(msg)
    if (!success) {
        if (!isEmptyString(res.msg)) {
            if (showToast === true) {
                AsToast.show(res.msg);
            }
        }
        if (isNeedToBanned) {
            //回调
            reqCallback && reqCallback(false, resObj, false, "");
            setTimeout(() => {
                window.location.replace(addRegion('/login'));
            }, 1000);
            return
        }
        if (isNeedToReLogin) {
            window.location.reload();
            return
        }
    }

    reqCallback && reqCallback(success, resObj, msg);
}

export const reqWebPushSave = async (data, onCallback, showLoading = false) => {
    const moreParams = { req: JSON.stringify(data || {}) };
    const apiCallback = (success, resObj, msg) => { }

    reqCommon(UrlWebPushSave, moreParams, null, apiCallback, null, null, showLoading, false);
}

export const reqAppConfig = async (onCallback, showLoading = true, isChildProduct = false) => {
    if (AppConfig.needUpdate()) {
        return;
    }

    if (isChildProduct === true) {
        AppConfig.checkChildDataSource(getProductName());
        if (AppConfig.childDataSource) {
            onCallback && onCallback(true, AppConfig.childDataSource);
            return;
        }
    } else {
        if (AppConfig.dataSource) {
            onCallback && onCallback(true, AppConfig.dataSource);
            return;
        }
    }

    let moreParams = null;
    if (isChildProduct === true) {
        moreParams = { product: getProductName() };
    }
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            //配置mobile列表
            const mobileContent = resObj.customize?.mobile || "";
            const mobileList = toList(mobileContent, ",");
            resObj.mobileList = mobileList;

            //配置email列表
            const emailContent = resObj.customize?.email || "";
            const emailList = toList(emailContent, ",");
            resObj.emailList = emailList;

            if (isChildProduct === true) {
                AppConfig.updateChildDataSource(getProductName(), resObj);
            } else {
                AppConfig.dataSource = resObj;
            }
        }

        if (AppConfig.needUpdate()) {
            return;
        }

        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlGetAppConfig, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqUserStateWithHistory = async (history, pageTag = PageTag.LAUNCH, onCallback, product = null, token = null, showLoading = true) => {

    const moreParams = null;
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            // 认证步骤
            let isProofStepMobile = false;
            let isProofStepFrequentlyUsedApps = false;
            let isProofStepFace = false;
            let isProofStepOcr = false;
            let isProofStepContact = false;
            let isProofStepOtherLoans = false;
            let isProofStepBankAccountOfFrZone = false;

            //当前手机号
            const wMobile = resObj.mobile || null;
            toLog("reqUserStateWithHistory wMobile: " + wMobile);

            //当前fullName
            const wFullName = resObj.fullName || null;
            setUserName(wFullName);

            //待做步骤
            const steps = resObj.steps;
            toLog("reqUserStateWithHistory" + steps);

            if (history) {
                //是否为审核中
                const isHomeReviewing = isLoanReviewing(resObj.applyStatus);
                //是否为拒绝
                const isHomeReject = isLoanReject(resObj.applyStatus, resObj.orderStatus);
                //是否为放款中
                const isHomeLoaning = isLoanLoaning(resObj.orderStatus);
                //是否为待还款
                const isHomeRepay = isLoanRepayNow(resObj.orderStatus);
                //是否为待进件
                const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay));
                toLog("API-isHomeReviewing: " + isHomeReviewing);
                toLog("API-isHomeReject: " + isHomeReject);
                toLog("API-isHomeLoaning: " + isHomeLoaning);
                toLog("API-isHomeRepay: " + isHomeRepay);
                toLog("API-isHomeNotLoan: " + isHomeNotLoan);

                //是否为好用户
                const isGoodUser = (resObj.PWDRecommendFlag || "") === "YES";
                const isTypeGoodUserLoginTag = (pageTag.indexOf("LOGIN_GOOD_USER") !== -1);

                if (isHomeReviewing) {
                    if (pageTag === PageTag.APPLY) {
                        history.replace(addRegion('/loansubmitprocessing'));
                    }
                    else if (pageTag === PageTag.REVIEW) {
                        history.replace(addRegion('/review'));
                    } else {
                        history.replace(addRegion('/productlist'));
                    }
                } else if (isHomeReject) {
                    if (pageTag === PageTag.REJECT) {
                        history.replace(addRegion('/reject'));
                    } else {
                        history.replace(addRegion('/productlist'));
                    }
                } else if (isHomeLoaning) {
                    if (pageTag === PageTag.LOANING) {
                        history.replace(addRegion('/loaning'));
                    } else {
                        history.replace(addRegion('/productlist'));
                    }
                } else if (isHomeRepay) {
                    if (pageTag !== PageTag.REPAY) {
                        history.replace(addRegion('/repaylist'));
                    }
                } else {
                    const isTypeHomeTag = (pageTag.indexOf("HOME") !== -1) || (pageTag.indexOf("PROOF") !== -1);
                    const isProofStepAllDone = isAllStepsFinish(steps);
                    if (isProofStepAllDone) {
                        if (isTypeHomeTag || isTypeGoodUserLoginTag) {
                            history.replace(addRegion('/loanpreview'));
                        } else {
                            history.replace(addRegion('/productlist'));
                        }
                    } else {
                        //取出第1个步骤
                        const nextStep = steps[0];
                        const allSteps = resObj.allSteps;
                        const stepTitles = resObj.stepTitles || resObj.allStepTitles;
                        const indexOfNextStep = allSteps.indexOf(nextStep);
                        const nextStepTitle = stepTitles[indexOfNextStep];
                        toLog("reqUserState nextStep: " + nextStep + ", inex: " + indexOfNextStep + ", title: " + nextStepTitle);

                        isProofStepMobile = isProofMobile(nextStep);
                        isProofStepFrequentlyUsedApps = isProofFrequentlyUsedApps(nextStep);
                        isProofStepFace = isProofFace(nextStep);
                        isProofStepOcr = isProofOcr(nextStep);
                        isProofStepContact = isProofContact(nextStep);
                        isProofStepOtherLoans = isProofOtherLoans(nextStep);
                        isProofStepBankAccountOfFrZone = isProofBankAccountOfFrZone(nextStep);

                        if (isTypeHomeTag || (isTypeGoodUserLoginTag && isGoodUser)) {
                            if (isProofStepMobile) {
                                if (pageTag !== PageTag.PROOF_MOBILE) {
                                    history.replace(addRegion('/proofmobile'));
                                }
                            }
                            else if (isProofStepFrequentlyUsedApps) {
                                if (pageTag !== PageTag.PROOF_FREQUENTLY_APPS) {
                                    history.replace(addRegion('/prooffrequentlyapps'));
                                }
                            }
                            else if (isProofStepFace) {
                                if (pageTag !== PageTag.PROOF_FACE) {
                                    history.replace(addRegion('/proofselfie'));
                                }
                            }
                            else if (isProofStepOcr) {
                                if (pageTag !== PageTag.PROOF_OCR) {
                                    history.replace(addRegion('/proofidentity'));
                                }
                            }
                            else if (isProofStepContact) {
                                if (pageTag !== PageTag.PROOF_CONTACT) {
                                    history.replace(addRegion('/proofcontact'));
                                }
                            }
                            else if (isProofStepOtherLoans) {
                                if (pageTag !== PageTag.PROOF_OTHER_LOANS) {
                                    history.replace(addRegion('/proofotherloans'));
                                }
                            }
                            else if (isProofStepBankAccountOfFrZone) {
                                if (pageTag !== PageTag.PROOF_WALLET) {
                                    history.replace(addRegion('/proofwallet'));
                                }
                            } else {
                                if (isGoodUser) {
                                    history.replace(addRegion('/proofgood'));
                                } else {
                                    history.replace(addRegion('/proof'));
                                }
                            }
                        } else {
                            history.replace(addRegion('/productlist'));
                        }
                    }
                }
            }
        }
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlGetUseState, moreParams, null, apiCallback, product, token, showLoading);
}

export const reqUserStateForProduct = async (history, pageTag = PageTag.LAUNCH, onCallback, showLoading = true) => {
    const moreParams = { token: getProductToken(), product: getProductName() };
    const apiCallback = (success, resObj, msg) => {
        // 页面渲染
        const curStep = {};
        const doneArray = [];
        const doingArray = [];
        const remaindArray = [];
        const curHeader = { current: 1, total: 1 };
        let isStepAllDone = false;

        if (success && resObj && history) {
            // 认证步骤
            let isProofStepMobile = false;
            let isProofStepFrequentlyUsedApps = false;
            let isProofStepFace = false;
            let isProofStepOcr = false;
            let isProofStepContact = false;
            let isProofStepOtherLoans = false;
            let isProofStepBankAccountOfFrZone = false;
            let isProofStepWallet = false;

            //是否为审核中
            const isHomeReviewing = isLoanReviewing(resObj.applyStatus);
            //是否为拒绝
            const isHomeReject = isLoanReject(resObj.applyStatus, resObj.orderStatus);
            //是否为放款中
            const isHomeLoaning = isLoanLoaning(resObj.orderStatus);
            //是否为待还款
            const isHomeRepay = isLoanRepayNow(resObj.orderStatus);
            //是否为待进件
            const isHomeNotLoan = ((!isHomeReviewing) && (!isHomeReject) && (!isHomeLoaning) && (!isHomeRepay));
            toLog("API-isHomeReviewing: " + isHomeReviewing);
            toLog("API-isHomeReject: " + isHomeReject);
            toLog("API-isHomeLoaning: " + isHomeLoaning);
            toLog("API-isHomeRepay: " + isHomeRepay);
            toLog("API-isHomeNotLoan: " + isHomeNotLoan);

            //是否为好用户
            const isGoodUser = (resObj.PWDRecommendFlag || "") === "YES";

            //如果成功，格式化数据
            //当前手机号
            const readonlyMobile = resObj.readonlyMobile || null;
            toLog("reqUserState readonlyMobile: " + readonlyMobile);

            //当前手机号
            const wMobile = resObj.mobile || null;
            toLog("reqUserState wMobile: " + wMobile);

            //当前fullName
            const wFullName = resObj.fullName || null;
            setUserName(wFullName);

            //待做步骤
            const steps = resObj.steps;
            toLog("reqUserState" + steps);

            // 是否完成所有步骤
            isStepAllDone = isAllStepsFinish(steps);
            resObj.isProofFinish = isStepAllDone;

            //取出第1个步骤
            if (!isStepAllDone) {
                resObj.isProofFinish = false;

                const nextStep = steps[0];
                const allSteps = resObj.allSteps;
                const stepTitles = resObj.stepTitles || resObj.allStepTitles;
                const indexOfNextStep = allSteps.indexOf(nextStep);
                let nextStepTitle = stepTitles[indexOfNextStep];

                // 判断是否为json格式
                const isJsonTitle = isJSONText(nextStepTitle);
                if (isJsonTitle) {
                    const jsonItem = JSON.parse(nextStepTitle);
                    nextStepTitle = jsonItem.en
                }
                toLog("reqUserState nextStep: " + nextStep + ", inex: " + indexOfNextStep + ", title: " + nextStepTitle);

                // 组装数据-curStep
                curStep.title = nextStepTitle;
                curStep.icon = pic_proof_basic;
                curStep.step = nextStep;

                if (isProofWork(nextStep)) {
                    curStep.icon = pic_proof_work;
                }

                // 组装数据-doneArray
                const difference = allSteps.filter(item => !steps.includes(item));
                for (let index = 0; index < difference.length; index++) {
                    const dif = difference[index];
                    const difIndex = allSteps.indexOf(dif);
                    let title = stepTitles[difIndex];
                    if (isJsonTitle) {
                        const jsonItem = JSON.parse(title);
                        title = jsonItem.en
                    }
                    doneArray.push({ title: title });
                }

                // 组装数据-curHeader
                curHeader.current = doneArray.length + 1;
                curHeader.total = allSteps.length;

                // 组装数据-remainArray
                for (let index = 1; index < steps.length; index++) {
                    const dif = steps[index];
                    const difIndex = allSteps.indexOf(dif);
                    let title = stepTitles[difIndex];
                    if (isJsonTitle) {
                        const jsonItem = JSON.parse(title);
                        title = jsonItem.en
                    }
                    remaindArray.push({ title: title });
                }

                isProofStepWallet = isProofBankAccount(nextStep)


                if (isProofMobile(nextStep)) {
                    isProofStepMobile = true;
                }

                else if (isProofFrequentlyUsedApps(nextStep)) {
                    isProofStepFrequentlyUsedApps = true;
                }

                else if (isProofFace(nextStep)) {
                    isProofStepFace = true;
                }

                else if (isProofOcr(nextStep)) {
                    isProofStepOcr = true;
                }

                else if (isProofOtherLoans(nextStep)) {
                    isProofStepOtherLoans = true;
                }

                else if (isProofBankAccountOfFrZone(nextStep)) {
                    isProofStepBankAccountOfFrZone = true;
                    curStep.icon = pic_proof_wallet;
                }

                else if (isProofContact(nextStep)) {
                    isProofStepContact = true;
                    curStep.icon = pic_proof_contact;

                    const nextFormItem = resObj[nextStep];
                    // 组装-doingArray
                    curStep.url = nextFormItem.apiUrl || "";

                    const itemCount = nextFormItem.itemCount;
                    //nextFormItem.dataList=[{},{},{}]
                    for (let i = 0; i < itemCount; i++) {
                        let itemList = [];
                        let relationships = [...nextFormItem.relationShips[i]];
                        for (let itemIndex = 0; itemIndex < nextFormItem.dataList.length; itemIndex++) {
                            let oldItem = nextFormItem.dataList[itemIndex];
                            let newItem = { ...oldItem };
                            newItem.title = removeTrailingAsterisk(newItem.title);

                            //如果action是 CONTACT_GROUP_RELATION, 组装values值
                            if (isActionSelect(oldItem.action)) {
                                //清空
                                newItem.values = [];
                                relationships.forEach(ship => {
                                    let relationship = {
                                        label: ship,
                                        title: ship,
                                        value: ship
                                    };
                                    newItem.values.push(relationship);
                                });
                            }

                            itemList[itemIndex] = newItem;
                        }
                        doingArray[i] = itemList;
                    }
                }

                else {
                    //取出对应的字段信息
                    const nextFormItem = resObj[nextStep];

                    //更新dataList中的content值，让它转变为受控状态
                    nextFormItem.extDataList && nextFormItem.extDataList.forEach(item => {
                        item.content = "";
                        item.show = false;
                        item.title = removeTrailingAsterisk(item.title);
                    });

                    //更新dataList中的content值，让它转变为受控状态
                    nextFormItem.dataList && nextFormItem.dataList.forEach(item => {
                        item.content = "";
                        item.realContent = "";
                        item.show = false;
                        item.title = removeTrailingAsterisk(item.title);

                        // 如果是银行字段，填充mobile
                        if (isProofBankAccount(nextStep)) {
                            if (item.paramName === "walletMobile") {
                                item.content = nextFormItem.mobile || "";
                                item.realContent = nextFormItem.mobile || "";
                            }
                        }

                        //工作、基本信息需要这些字段
                        item.stateContent = "";
                        item.cityContent = "";
                        item.areaContent = "";

                        //银行卡页需要这个字段
                        item.bankCodeContent = ""

                        // 组装-doingArray
                        curStep.url = nextFormItem.apiUrl || "";
                        doingArray.push({ ...item });
                    });

                    toLog(nextFormItem);
                    resObj = nextFormItem;
                }
            }

            if (isHomeReviewing) {
                history.replace(addRegion('/review'));

            } else if (isHomeReject) {
                history.replace(addRegion('/reject'));

            } else if (isHomeLoaning) {
                history.replace(addRegion('/loaning'));

            } else if (isHomeRepay) {
                history.replace(addRegion('/repay'));

            } else {
                const isTypeHomeTag = (pageTag.indexOf("HOME") !== -1) || (pageTag.indexOf("PROOF") !== -1);
                if (isStepAllDone) {
                    if (isTypeHomeTag) {
                        history.replace(addRegion('/loanpreview'));
                    } else {
                        history.replace(addRegion('/productlist'));
                    }
                } else {
                    if (isTypeHomeTag) {
                        if (isProofStepMobile) {
                            history.replace(addRegion('/proofmobile'));
                        }
                        else if (isProofStepFrequentlyUsedApps) {
                            history.replace(addRegion('/prooffrequentlyapps'));
                        }
                        else if (isProofStepFace) {
                            history.replace(addRegion('/proofselfie'));
                        }
                        else if (isProofStepOcr) {
                            history.replace(addRegion('/proofidentity'));
                        }
                        else if (isProofStepContact) {
                            history.replace(addRegion('/proofcontact'));
                        }
                        else if (isProofStepOtherLoans) {
                            history.replace(addRegion('/proofotherloans'));
                        }
                        else if (isProofStepBankAccountOfFrZone) {
                            history.replace(addRegion('/proofwallet'));
                        }
                        else {
                            if (isGoodUser && isProofStepWallet) {
                                history.push(addRegion('/proofgood'));
                            } else {
                                history.push(addRegion('/proof'));
                            }
                        }
                    } else {
                        history.replace(addRegion('/productlist'));
                    }
                }
            }
        }

        onCallback && onCallback(success, resObj, curStep, doneArray, doingArray, remaindArray, curHeader, isStepAllDone);
    }
    reqCommon(UrlGetUseState, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqUserStateForPersonal = async (onCallback, product = null, token = null, showLoading = true) => {
    const moreParams = null;
    const apiCallback = (success, resObj, msg) => {
        const userInfo = {};
        if (success && resObj) {
            //当前fullName
            const wFullName = resObj.fullName || null;
            setUserName(wFullName);

            userInfo.fullName = resObj.fullName || "";
            userInfo.email = resObj.email || "";
            userInfo.mobile = resObj.mobile || "";
            userInfo.birthDate = resObj.birthDate || "";
        }
        onCallback && onCallback(success, userInfo);
    }
    reqCommon(UrlGetUseState, moreParams, null, apiCallback, product, token, showLoading);
}

export const reqOtp = async (mobile, submitMobile, isWA, pageType = "LOGINPAGE", onCallback, showLoading = true) => {
    let sendTypeFormat = "SMS";
    if (isWA === true) {
        sendTypeFormat = "WHATSAPP";
    } else {
        sendTypeFormat = "SMS";
    }

    const moreParams = { mobile: mobile, submitMobile: submitMobile, sendType: sendTypeFormat, pageType: pageType };
    const apiCallback = (success, resObj, msg) => {
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlGetOtp, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqLogin = async (mobile, code, directLoginFlag = "NO", onCallback, showLoading = true) => {

    //来源
    const dreferrer = document.referrer || "";

    const moreParams = { mobile: mobile, code: code, directLoginFlag: (directLoginFlag || ""), docReferrer: (dreferrer || "") };
    const apiCallback = (success, resObj, msg) => {
        if (success) {
            //保存号码
            resObj.mobile = mobile + "";
            resObj.token = resObj.token || "";
            setUserInfo(resObj);
            setChatMobile(mobile + "");

            const tk = resObj.token + "";
            const p = process.env.REACT_APP_PRODUCT + "";
            setProductName(p);
            setProductToken(tk);

            // add firebase register event
            let type = resObj.type || "";
            if (type === "REGISTER") {
                addRegisterEvent();
                // eslint-disable-next-line no-undef
                // gtagSendEvent();
            }
        }
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlLogin, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqNoCodeLogin = async (mobile, onCallback, showLoading = true) => {
    //来源
    const dreferrer = document.referrer || "";
    const moreParams = { mobile: mobile, code: "", directLoginFlag: "NO", notBindWalletFlag: "YES", docReferrer: (dreferrer || "") };
    const apiCallback = (success, resObj, msg) => {
        if (success) {
            //保存号码
            resObj.mobile = mobile + "";

            const tk = resObj.token + "";
            const p = process.env.REACT_APP_PRODUCT + "";
            setProductName(p);
            setProductToken(tk);

            // add firebase register event
            let type = resObj.type || "";
            if (type === "REGISTER") {
                setUserInfo(resObj);
                setChatMobile(mobile + "");

                addRegisterEvent();
                // eslint-disable-next-line no-undef
                // gtagSendEvent();
            } else if (type === "LOGIN") {
                setUserInfo(resObj);
                setChatMobile(mobile + "");
            }

            resObj.isNeedOtp = type === "CAPTCHA";
        }
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlUserLoginNoCode, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqProofWalletBindOtp = async (mobile, onCallback, showLoading = true) => {
    const moreParams = { mobile: mobile, product: getProductName() };
    const apiCallback = (success, resObj, msg) => {
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlBindBankAccountSmsCode, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqSaveProofStepData = async (curStepInfo, proofData, onCallback, showLoading = true) => {

    const url = curStepInfo.url || "";
    const moreParams = { ...proofData };
    const apiCallback = (success, resObj, msg) => {
        if (success) {
            const curStep = curStepInfo.step || "";
            reqSaveEvent(endPageFlag(EventTag.PROOF + curStep));
            addProofStepDoneEvent(curStep + "_done");
        }
        onCallback && onCallback(success, resObj);
    }
    reqCommon(url, moreParams, null, apiCallback, getProductName(), getProductToken(), showLoading);
}

export const reqLoanPreview = async (amount, minAmount, maxAmount, days, onCallback, showLoading = true) => {
    const moreParams = { token: getProductToken(), product: getProductName(), amount: (amount || "0"), minAmount: (minAmount || ""), maxAmount: (maxAmount || ""), days: (days || "") };
    const apiCallback = (success, resObj, msg) => {
        // 组装-dayArray
        const dayArray = [];
        // 组装-billArray
        const bill1Array = [];
        const bill2Array = [];

        if (success && resObj) {
            // 组装-dayArray
            const daysArray = resObj.daysArray || [];
            const days = resObj.days || "";
            for (let index = 0; index < daysArray.length; index++) {
                const day = (daysArray[index] + "");
                const item = { day: day, isChecked: day === days, canChecked: true };
                dayArray.push(item);
            }

            // 组装-billArray
            const detailItemList = resObj.detailItemList || [];
            let firstTitleIndex = -1;
            let secondTitleIndex = -1;
            for (let index = 0; index < detailItemList.length; index++) {
                const item = { ...detailItemList[index] };
                const isTitle = item.title === "YES";
                item.isTitle = isTitle;

                if (isTitle) {
                    if (firstTitleIndex >= 0) {
                        secondTitleIndex = index;
                        break;
                    } else {
                        firstTitleIndex = index;
                    }
                }

                bill1Array.push(item);
            }

            for (let index = secondTitleIndex; index < detailItemList.length; index++) {
                const item = { ...detailItemList[index] };
                const isTitle = item.title === "YES";
                item.isTitle = isTitle;
                bill2Array.push(item);
            }
        }
        onCallback && onCallback(success, resObj, dayArray, bill1Array, bill2Array);
    }
    reqCommon(UrlGetLoanDetailPageForDays, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqLoanPreviewForLoanStatus = async (onCallback, showLoading = true) => {
    const moreParams = { token: getProductToken(), product: getProductName() };
    const apiCallback = (success, resObj, msg) => {
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlGetLoanDetail, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqLoanOrder = async (amount = "", days = "", code = "", onCallback, showLoading = true) => {
    const moreParams = { token: getProductToken(), product: getProductName(), amount: amount || "", days: days || "", code: code };
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            let orderId = resObj.orderId || "";
            addPurchseEvent(orderId);
        }
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlConfirmLoanApply, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqUserWalletList = async (onCallback, showLoading = true) => {
    const moreParams = { token: getProductToken(), product: getProductName() };
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            for (let index = 0; index < resObj.length; index++) {
                const item = resObj[index];
                item.accountFormat = toEllipsisMobile(item.account);
            }
        }
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlGetBindCardList, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqUserWalletListForProofStep = async (onCallback, showLoading = true) => {
    const moreParams = { token: getProductToken(), product: getProductName() };
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            for (let index = 0; index < resObj.length; index++) {
                const item = resObj[index];
                item.account = item.walletMobile;
                item.name = item.walletDesc;
                item.logo = item.walletLogo;
                item.accountFormat = toEllipsisMobile(item.account);
            }

            const walletNumberExt = i18n.t('walletNumberExt');
            const edit = i18n.t('edit');

            if (resObj.length === 0) {
                resObj.push({ title: walletNumberExt, name: edit, account: "", logo: "", isAdd: true });
                resObj.push({ title: walletNumberExt, name: edit, account: "", logo: "", isAdd: true });

            } else if (resObj.length === 1) {
                resObj.push({ title: walletNumberExt, name: edit, account: "", logo: "", isAdd: true });
            }

            for (let index = 0; index < resObj.length; index++) {
                const item = resObj[index];
                const title = walletNumberExt + (index + 1);
                item.title = title;
            }
        }
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlFetchBoundBankAccount, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqProductList = async (onCallback, showLoading = true) => {
    const moreParams = { mobile: getLoginMobile() };
    const apiCallback = (success, resObj, msg) => {
        const applyArray = [];
        const appliedArray = [];
        const repayArray = [];

        if (success && resObj) {

            const extDaysOverdue = i18n.t('extDaysOverdue');
            const extDaysLeft = i18n.t('extDaysLeft');

            for (let index = 0; index < resObj.length; index++) {
                const item = resObj[index];
                const status = item.status || "";
                const applyStatus = item.applyStatus || "";
                const interestRate = item.interestRate || "";
                const interestRateFormat = toPercentageString(interestRate, 1);
                item.interestRateFormat = interestRateFormat;
                // status APPLY可申请  PENDING处理中  REPAYMENT待还款
                // applyStatus NO可申请  其他值跟getUserProcess里applyStatus逻辑一致
                if (status === "APPLY") {
                    if ((applyStatus === ApplyStatus.NO) || (applyStatus === ApplyStatus.SUCCEEDED)) {
                        applyArray.push(item);

                    } else if (applyStatus.indexOf("DENIED") !== -1) {
                        item.statusFormat = "REJECT";
                        appliedArray.push(item);

                    } else if (applyStatus === ApplyStatus.DS_APROVAL) {
                        item.statusFormat = "LOANING";
                        appliedArray.push(item);
                    }

                } else if (status === "PENDING") {
                    item.statusFormat = "REVIEW";

                    if (applyStatus === ApplyStatus.SUCCEEDED) {
                        item.statusFormat = "LOANING";
                    }
                    appliedArray.push(item);

                } else if (status === "REPAYMENT") {
                    item.statusFormat = "REPAYMENT";
                    const overdueDay = item.overdueDay || "0";
                    item.overdueDay = overdueDay;
                    item.isOverdue = parseInt(overdueDay) > 0;
                    if (item.isOverdue) {
                        item.statusDes = overdueDay + extDaysOverdue;
                    } else {
                        const repaymentDate = item.repaymentDate || "";
                        const difDays = daysDifference(repaymentDate);
                        item.statusDes = difDays + extDaysLeft;
                    }

                    repayArray.push(item);
                    appliedArray.push(item);
                }

                else {
                    item.statusFormat = "REVIEW";
                    if (status === "REPAYMENT") {
                        item.statusFormat = "REPAYMENT";
                        const overdueDay = item.overdueDay || "0";
                        item.overdueDay = overdueDay;
                        item.isOverdue = parseInt(overdueDay) > 0;
                        if (item.isOverdue) {
                            item.statusDes = overdueDay + extDaysOverdue;
                        } else {
                            const repaymentDate = item.repaymentDate || "";
                            const difDays = daysDifference(repaymentDate);
                            item.statusDes = difDays + extDaysLeft;
                        }

                        repayArray.push(item);

                    } else if (status === "REJECT") {
                        item.statusFormat = "REJECT";

                    } else if (status === "PENDING") {
                        if (applyStatus === ApplyStatus.DS_APROVAL) {
                            item.statusFormat = "LOANING";
                        }
                    }

                    appliedArray.push(item);
                }
            }
        }

        onCallback && onCallback(success, resObj, applyArray, appliedArray, repayArray);
    }
    reqCommon(UrlQueryNewStyleProducts, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqProductCopy = async (sourceProduct, targetProduct, onCallback, showLoading = true) => {
    const moreParams = { sourceProduct: sourceProduct, targetProduct: targetProduct };
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            const resToken = resObj.token || "";
            if (resToken.length > 0) {
                setProductName(targetProduct);
                setProductToken(resToken);
            }
        }
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlOneKeyCopyToApply, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqDeniedDcList = async (onCallback, showLoading = true) => {
    const moreParams = { token: getProductToken(), product: getProductName() };
    const apiCallback = (success, resObj, msg) => {
        let dcProductList = [];
        if (success && resObj) {
            // const dcFlag = resObj.dcFlag || "NO";
            const pList = resObj.dcProductList || [];
            dcProductList = [...pList]
        }
        onCallback && onCallback(success, dcProductList);
    }
    reqCommon(UrlUserDeniedDc, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqAddWallet = async (mobile, channel, addBindFlag = "NO", onCallback, showLoading = true) => {
    const moreParams = { walletMobile: (mobile || ""), walletMobileConfirm: (mobile || ""), walletChannel: (channel || ""), addBindFlag: (addBindFlag || "") };
    const apiCallback = (success, resObj, msg) => {
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlBindBankAccount, moreParams, null, apiCallback, getProductName(), getProductToken(), showLoading);
}

export const reqDeleteWallet = async (ids, onCallback, showLoading = true) => {
    const moreParams = { ids: (ids || "") };
    const apiCallback = (success, resObj, msg) => {
        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlDeleteBoundBankAccount, moreParams, null, apiCallback, getProductName(), getProductToken(), showLoading);
}

export const reqRepayDetail = async (onCallback, showLoading = true) => {
    const moreParams = null;
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            const extDaysOverdue = i18n.t('extDaysOverdue');
            const extDaysLeft = i18n.t('extDaysLeft');

            const remainRepayDay = resObj.remainRepayDay || "0";
            const overdueDay = resObj.overdueDay || "0";

            const remainRepayDayInt = parseInt(remainRepayDay);
            const overdueDayInt = parseInt(overdueDay);

            if (overdueDayInt > 0) {
                resObj.statusDes = overdueDayInt + extDaysOverdue;
            } else {
                resObj.statusDes = remainRepayDayInt + extDaysLeft;
            }
        }

        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlGetReapyDetail, moreParams, null, apiCallback, getProductName(), getProductToken(), showLoading);
}

export const reqRepayUrl = async (onCallback, showLoading = true) => {
    const moreParams = { annulType: "TEST" };
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            let repayUrl = resObj.repayUrl || "";
            const url = resObj.url || "";
            if (repayUrl.length === 0 && url.length === 0) {
                resObj.isTypePin = true;
                const thirdOrderId = resObj.thirdOrderId || "";
                setPinOrderId(thirdOrderId);

            } else {
                resObj.isTypePin = false;
                if (repayUrl.length === 0) {
                    repayUrl = url + "";
                }
            }
        }

        onCallback && onCallback(success, resObj);
    }
    reqCommon(UrlGetReapyUrl, moreParams, null, apiCallback, getProductName(), getProductToken(), showLoading);
}

export const reqRepayPinStatus = async (onCallback, showLoading = true) => {
    const moreParams = { thirdOrderId: getPinOrderId() };
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            const status = resObj.status || "PROCESSING";
            resObj.isProcessing = true;

            if (status.indexOf("PROC") !== -1) {
                resObj.isProcessing = true;

            } else if (status.indexOf("SUCC") !== -1) {
                resObj.isSuccessed = true;

            } else if (status.indexOf("FAIL") !== -1) {
                resObj.isFailed = true;
            }
        }
        onCallback && onCallback(success, resObj);
    }

    reqCommon(UrlQueryPaymentStatus, moreParams, null, apiCallback, getProductName(), getProductToken(), showLoading);
}

export const reqUploadImage = async (image, onCallback, showLoading = true) => {
    if (showLoading && showLoading === true) {
        AsLoading.show();
    }

    //组装参数
    const params = baseParams();
    params.token = getProductToken();
    params.product = getProductName();

    //压图片
    const formData = new FormData();
    formData.append('image', image);

    //压参数
    for (const key in params) {
        formData.append(key, params[key]);
    }

    const headers = baseUploadHeaders();

    //发起请求
    const res = await uploadHttp.post(toFullApi(UrlUploadImage), formData, headers);
    const resCode = res.code || "-1";
    const resObj = res.obj
    const msg = res.msg || ""
    const success = (resCode === "0000");

    const url = resObj.url || "";
    toLog("reqUploadImage-end: =======");

    if (showLoading && showLoading === true) {
        AsLoading.hide();
    }

    //如果有错误，提示
    if (!success) {
        if (!isEmptyString(msg)) {
            AsToast.show(res.msg);
        }
    }

    onCallback && onCallback(success, url);
}

export const reqSaveFace = async (faceImgUrl, onCallback, showLoading = true) => {
    const moreParams = { faceImgUrl: faceImgUrl, cardType: "FACE" };
    const apiCallback = (success, resObj, msg) => {
        onCallback && onCallback(success, resObj);
    }

    reqCommon(UrlSaveFaceInfo, moreParams, null, apiCallback, getProductName(), getProductToken(), showLoading);
}

export const reqSaveOcr = async (cardImgUrl, cardBackImgUrl, cardType, onCallback, showLoading = true) => {
    const moreParams = { cardImgUrl: cardImgUrl || "", cardBackImgUrl: cardBackImgUrl || "", cardType: cardType || "IDCARD" };
    const apiCallback = (success, resObj, msg) => {
        onCallback && onCallback(success, resObj);
    }

    reqCommon(UrlSaveOcrInfo, moreParams, null, apiCallback, getProductName(), getProductToken(), showLoading);
}

export const reqSaveFeedback = async (type, opinion, images, onCallback, showLoading = true) => {
    const moreParams = { type: (type || ""), opinion: (opinion || ""), images: (images || "") };
    const apiCallback = (success, resObj, msg) => {
        onCallback && onCallback(success, resObj);
    }

    reqCommon(UrlFeedback, moreParams, null, apiCallback, null, null, showLoading);
}

export const reqSaveLocation = async (longitude = "", latitude = "", page = "") => {
    const moreParams = { latitude: latitude, longitude: longitude, page: page };
    reqCommon(UrlSaveUserLocation, moreParams, null, null, null, null, false);
}

export const reqSaveDeviceStore = async () => {
    const moreParams = { ram: (navigator.deviceMemory || "") + "GB" };
    reqCommon(UrlSaveDeviceInfo, moreParams, null, null, null, null, false);
}

export const reqSimpleData = async (params, onCallback, showLoading = true) => {
    const moreParams = null;
    const fullUrl = UrlGetSimpleData + "/" + params;
    const apiCallback = (success, resObj, msg) => {
        if (success && resObj) {
            const bankCode = resObj.bankCode || "";
            const lastAsteriskIndex = bankCode.lastIndexOf('*');
            resObj.bankCodeFormat = lastAsteriskIndex !== -1
                ? bankCode.slice(0, lastAsteriskIndex + 1) + ' ' + bankCode.slice(lastAsteriskIndex + 1)
                : bankCode;

            // save gu mobile
            const mobile = resObj.mobile || "";
            setGoodUserMobile(mobile);
        }
        onCallback && onCallback(success, resObj);
    }
    reqCommonForGet(fullUrl, moreParams, null, apiCallback, null, null, showLoading);
}
