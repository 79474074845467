import React, { useEffect, useState } from 'react';
import './copyinstalllinkdialog.css'
import { reqSaveEvent } from '@/store/actions/apiServices';
import { useTranslation } from 'react-i18next';
import { copyLabel } from '@/utils/utils';

import icon_install_link_copy from "@/assets/icon_install_link_copy.webp"
import icon_app_logo from "@/assets/icon_app_logo.webp"
import AsFlowDiagram from '@/pages/Install/components/AsFlowDiagram/asFlowDiagram';
import AsToast from '../AsToast/asToast';


export default function CopyInstallLinkDialog({ visible, onCopy }) {

    const { t } = useTranslation();

    const [curLink, setCurLink] = useState("");

    useEffect(() => {
        let linkText = process.env.REACT_APP_LANDING_URL + "";
        setCurLink(linkText);
    }, [visible])


    const onHandleCopyClick = () => {
        reqSaveEvent("COPY_INSTALL_LINK_COPY_CLICK", "")

        const fullText = process.env.REACT_APP_LANDING_URL + "";

        if (navigator.clipboard && navigator.permissions) {
            navigator.clipboard.writeText(fullText)
                .then(function () {
                    AsToast.show(t('copyInstallLinkDialog3'));

                })
                .catch(function () {
                    copyLabel(fullText);
                });
        } else {
            copyLabel(fullText);
        }

        onCopy && onCopy();

        if (!AsFlowDiagram.isOpenInChrome()) {
            AsFlowDiagram.openInChrome(fullText);
            return;
        }
        // window.open(fullText, '_blank');
    }

    return (
        <div className="copyInstallLinkDialogOverlayBg copyInstallLinkDialogFadeIn">
            <div className="copyInstallLinkDialogOverlayContent">

                <div className='copyInstallLinkDialogTitleRow'>
                    <img className='copyInstallLinkDialogTitleLogo' src={icon_app_logo} alt='' />
                </div>

                <div className='copyInstallLinkDialogTitle copyInstallLinkDialogTitleBold'>{t('copyInstallLinkDialog4')}</div>

                <div className='copyInstallLinkDialogDetail copyInstallLinkDialogTitleBold'>{t('copyInstallLinkDialog1')}</div>

                <div className='copyInstallLinkDialogTips copyInstallLinkDialogMarginTop16Px'>
                    {t('copyInstallLinkDialog2')}
                </div>

                <div className='copyInstallLinkDialogCopyContent' onClick={onHandleCopyClick}>
                    <div className='copyInstallLinkDialogCopyLink'>{curLink}</div>
                    <img className='copyInstallLinkDialogCopyIcon' src={icon_install_link_copy} alt='' />
                </div>

                {/* <img className='copyInstallLinkDialogCloseIcon' src={icon_open_chrome_dialog_lose} alt='' onClick={onHandleCloseClick}></img> */}

            </div>
        </div>
    );
};
