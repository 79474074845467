import React from 'react';
import './index.css';
// import '@scss/index.scss'
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import AppParams from "@/utils/appParams";
import './i18n';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { toLog } from './utils/utils';
import DefaultLang from './useLanguage';

function setDynamicParams() {
  const currentUrl = window.location.href;
  const searchParams = new URLSearchParams(window.location.search);
  const referrer = searchParams.get('referrer');
  const tid = searchParams.get('tid');

  AppParams.fullUrl = currentUrl
  AppParams.urlParams = searchParams.toString()
  AppParams.referrer = referrer || ""
  AppParams.tid = tid || ""
  AppParams.printlog();
}
setDynamicParams();

function setDynamicManifest() {
  DefaultLang.detectLanguage();

  const manifestBasePath = "/";
  const manifestFile = DefaultLang.region
    ? `manifest-${DefaultLang.region}.json`
    : "manifest.json";

  const manifestLink = document.getElementById("dynamic-manifest");
  if (manifestLink) {
    manifestLink.setAttribute("href", `${manifestBasePath}${manifestFile}`);
    toLog(`Manifest set to: ${manifestBasePath}${manifestFile}`);
  }
}
setDynamicManifest();

const root = createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nextProvider>
);
