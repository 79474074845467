
import './App.css';
import './globalShape.css';
import './globalText.css';
import './globalMargin.css';

import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { toLog } from './utils/utils';
import DefaultLang from './useLanguage';
import DeviceID from './utils/deviceId';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import pic_loading_lazy from "./assets/pic_loading_lazy.webp"
// import AsOpenFail from './pages/Install/components/AsOpenFail/AsOpenInChrome/asOpenFail';
import CopyInstallLinkDialog from './components/CopyInstallLinkDialog/CopyInstallLinkDialog';

const Launch = React.lazy(() => import('./pages/Launch/launch'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy/privacyPolicy'));
const TermOfUse = React.lazy(() => import('./pages/PrivacyPolicy/termOfUse'));
const LoginInputMobile = React.lazy(() => import('./pages/LoginV3/loginInputMobile'));
const LoginInputOtp = React.lazy(() => import('./pages/LoginV3/loginInputOtp'));
const ProductList = React.lazy(() => import('./pages/ProductList/productList'));
const LoanPreview = React.lazy(() => import('./pages/LoanV3/loanPreview'));
const LoanSubmitProcessing = React.lazy(() => import('./pages/LoanV3/loanSubmitProcessing'));
const Review = React.lazy(() => import('./pages/ReviewV3/review'));
const Loaning = React.lazy(() => import('./pages/ReviewV3/loaning'));
const Reject = React.lazy(() => import('./pages/ReviewV3/reject'));
const RepaymentList = React.lazy(() => import('./pages/RepaymentV3/repaymentList'));
const RepaymentDetail = React.lazy(() => import('./pages/RepaymentV3/repaymentDetail'));
const PinRepayProcessing = React.lazy(() => import('./pages/RepaymentV3/pinRepayProcessing'));
const PinRepaySuccess = React.lazy(() => import('./pages/RepaymentV3/pinRepaySuccess'));
const PinRepayFail = React.lazy(() => import('./pages/RepaymentV3/pinRepayFail'));
const Me = React.lazy(() => import('./pages/MeV3/me'));
const MyProfile = React.lazy(() => import('./pages/MeV3/myProfile'));
const MyIcon = React.lazy(() => import('./pages/MeV3/myIcon'));
const Setting = React.lazy(() => import('./pages/MeV3/setting'));
const Feedback = React.lazy(() => import('./pages/MeV3/feedback'));
const Proof = React.lazy(() => import('./pages/ProofV3/proof'));
const ProofContact = React.lazy(() => import('./pages/ProofV3/proofContact'));
const ProofIdentity = React.lazy(() => import('./pages/ProofV3/proofIdentity'));
const ProofSelfie = React.lazy(() => import('./pages/ProofV3/proofSelfie'));
const ProofWallet = React.lazy(() => import('./pages/ProofV3/proofWallet'));
const RegionWrapper = React.lazy(() => import('./RegionWrapper'));
const RegionRedirect = React.lazy(() => import('./RegionRedirect'));
const ProofGoodUser = React.lazy(() => import('./pages/ProofV3/proofGoodUser'));
const Website = React.lazy(() => import('./pages/Website/website'));
const InstallGoodUser = React.lazy(() => import('./pages/Install/install'));
const Landing = React.lazy(() => import('./pages/Landing/landing'));



function CatalogInstallDialog() {

  DefaultLang.detectLanguage();
  const isDebugMode = (process.env.REACT_APP_DEBUG_MODE + "") === "YES";

  return (
    (!isDebugMode) && DefaultLang.isNeedPwaInstall() && (
      <CopyInstallLinkDialog
        visible={true}
        onClose={() => toLog('Dialog closed')}
        onCopy={() => toLog('Copied')} />
    )
  );
}

function App() {

  const { i18n } = useTranslation();

  // const isNotOpenInApp = () => {
  //   DefaultLang.detectLanguage();
  //   const isDebugMode = (process.env.REACT_APP_DEBUG_MODE + "") === "YES";
  //   const isNeedPwaInstall = DefaultLang.isNeedPwaInstall();
  //   return ((!isDebugMode) && isNeedPwaInstall);
  // }

  useEffect(() => {
    i18n.changeLanguage(DefaultLang.language);

    window.addEventListener("onAppDidInstalled", (e) => {
      toLog("found: onAppDidInstalled");
    });

    // if (window.wpSubscription) {
    // try {
    //   reqWebPushSave(window.wpSubscription || {});
    // } catch (e) {
    //   //
    // }
    // }

    // window.addEventListener("onWebPushInited", (e) => {
    //   toLog("found: onWebPushInited");
    // try {
    //   reqWebPushSave(window.wpSubscription, () => { });
    // } catch (e) {
    //   //
    // }
    // });

    if (!DeviceID.isValidVistorId()) {
      const fpPromise = FingerprintJS.load();
      fpPromise.then(fp => fp.get()).then(result => {
        DeviceID.saveVisitorId(result.visitorId);
        toLog("launch fingerprint: " + result.visitorId);
      });
    }

    // if (isNotOpenInApp()) {
    //   AsOpenFail.show();
    // }
  }, [])

  return (
    <BrowserRouter>
      <div className="AppRoot">
        <div className="App">
          <RegionWrapper>
            {/* <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100vw', height: '100vh', fontSize: '16px', color: '#3B7EEE' }}>Loading...</div>}> */}
            <Suspense fallback={<img className='spinner' src={pic_loading_lazy} style={{ position: 'absolute', left: '50%', top: '50%', marginLeft: '-20px', marginTop: '-20px', width: '40px', height: '40px', objectFit: 'contain' }} alt='' />}>
              <Switch>
                <Route exact path="/:region/website" component={Website} />

                <Route exact path="/:region/landing" component={Landing} />

                <Route exact path="/:region/:catalog/gooduser/:product" component={InstallGoodUser} />

                <Route exact path="/:region/:catalog/launch" component={Launch} />

                <Route exact path="/:region/:catalog/login" component={LoginInputMobile} />

                <Route exact path="/:region/:catalog/loginotp" component={LoginInputOtp} />

                <Route exact path="/:region/:catalog/productlist" component={ProductList} />

                <Route exact path="/:region/:catalog/proof" component={Proof} />

                <Route exact path="/:region/:catalog/proofgood" component={ProofGoodUser} />

                <Route exact path="/:region/:catalog/proofcontact" component={ProofContact} />

                <Route exact path="/:region/:catalog/proofidentity" component={ProofIdentity} />

                <Route exact path="/:region/:catalog/proofselfie" component={ProofSelfie} />

                <Route exact path="/:region/:catalog/proofwallet" component={ProofWallet} />

                <Route exact path="/:region/:catalog/loanpreview" component={LoanPreview} />

                <Route exact path="/:region/:catalog/loansubmitprocessing" component={LoanSubmitProcessing} />

                <Route exact path="/:region/:catalog/review" component={Review} />

                <Route exact path="/:region/:catalog/loaning" component={Loaning} />

                <Route exact path="/:region/:catalog/reject" component={Reject} />

                <Route exact path="/:region/:catalog/repaylist" component={RepaymentList} />

                <Route exact path="/:region/:catalog/repaydetail" component={RepaymentDetail} />

                <Route exact path="/:region/:catalog/pinrepayprocessing" component={PinRepayProcessing} />

                <Route exact path="/:region/:catalog/pinrepaysuccess" component={PinRepaySuccess} />

                <Route exact path="/:region/:catalog/pinrepayfail" component={PinRepayFail} />

                <Route exact path="/:region/:catalog/me" component={Me} />

                <Route exact path="/:region/:catalog/myprofile" component={MyProfile} />

                <Route exact path="/:region/:catalog/myicon" component={MyIcon} />

                <Route exact path="/:region/:catalog/setting" component={Setting} />

                <Route exact path="/:region/:catalog/feedback" component={Feedback} />

                <Route exact path="/:region/:catalog/privacy" component={PrivacyPolicy} />

                <Route exact path="/:region/:catalog/termofuse" component={TermOfUse} />

                <Route path="/" component={RegionRedirect} />
              </Switch>
            </Suspense>
          </RegionWrapper>
        </div>

        <CatalogInstallDialog />

      </div>
    </BrowserRouter >
  );
}

export default App;
