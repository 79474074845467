import { toLog } from "./utils";



export default class AppConfig {
    static dataSource = null;
    static childDataSource = null;

    static childProductDataMap = {};

    static checkChildDataSource(product) {
        if (AppConfig.childProductDataMap && product) {
            let obj = AppConfig.childProductDataMap[product];
            AppConfig.childDataSource = obj;

        } else {
            AppConfig.childDataSource = null;
        }
    }

    static updateChildDataSource(product, obj) {
        if (product) {
            AppConfig.childProductDataMap[product] = obj;
            AppConfig.childDataSource = obj;

        } else {
            AppConfig.childDataSource = null;
        }
    }

    static needUpdate() {
        if (AppConfig.dataSource) {
            const cpVersion = AppConfig.dataSource.customize?.h5Version || "0";
            const h5Version = (process.env.REACT_APP_VERSION + "") || "0";

            try {
                const cpVersionInt = parseInt(cpVersion);
                const h5VersionInt = parseInt(h5Version);

                console.log(`${cpVersionInt}  ${h5VersionInt}`);

                const isMust = cpVersionInt > h5VersionInt;
                if (isMust) {
                    window.location.reload();
                }
                return isMust;

            } catch (error) {
                toLog(error);
                return false;
            }
        }
        return false;
    }
    /** 打印输出 */
    static printlog() {
        toLog("=======App 配置：")
        toLog("=======dataSource: " + AppConfig.dataSource);
    }
}