/** 订单状态 */
export const OrderStatus = {

    /**
    * 未知
    */
    NO: "NO",

    /**
     * 创建
     */
    CREATED: "CREATED",

    /**
     * 拒绝
     */
    DENIED: "DENIED",

    /**
     * 放款中
     */
    LENDING: "LENDING",

    /**
     * 待还款，未逾期
     */
    LOANED: "LOANED",

    /**
     * 待还款，逾期
     */
    OVERDUE: "OVERDUE",

    /**
     * 结清
     */
    SETTLE: "SETTLE",

    /**
     * 逾期结清
     */
    OVERDUE_SETTLE: "OVERDUE_SETTLE",

    /**
     * 手动结清
     */
    ANNUL_SETTLE: "ANNUL_SETTLE",

    /**
     * 逾期手动结清
     */
    OVERDUE_ANNUL_SETTLE: "OVERDUE_ANNUL_SETTLE",

    /**
     * 坏账
     */
    BAD_DEBT: "BAD_DEBT"
}

