import { combineReducers } from "redux";
import { appConfig } from "./appConfig";


// 组合各个 reducer 函数，成为一个根 reducer
const rootReducer = combineReducers({
    appConfig
})

// 导出根 reducer
export default rootReducer