import axios from "axios";
import { BaseUrl } from "./apiUrl";
import DefaultLang from "@/useLanguage";
import { toLog } from "./utils";

const uploadHttp = axios.create({
    baseURL: BaseUrl,
    timeout: 500000,
    withCredentials: true
})

uploadHttp.interceptors.request.use(config => {
    toLog(config.data)
    return config
})

uploadHttp.interceptors.response.use(response => {
    toLog(response.data)
    return response.data
}, err => {
    return Promise.reject(err)
})

export default uploadHttp

export const baseUploadHeaders = () => {
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'country': DefaultLang.country
        }
    };
};
