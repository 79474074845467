import { toLog } from "./utils"


/**
 * App 传过来的接口入参
 */
export default class AppParams {
    /** url */
    static fullUrl = ""

    /** params */
    static urlParams = ""

    /** referrer */
    static referrer = ""

    /** t */
    static tid = ""

    /** currency */
    static currency = "GHS"

    static installReload = false;

    /** 打印输出 */
    static printlog() {
        toLog("->url: " + AppParams.fullUrl)
        toLog("->params: " + AppParams.urlParams)
        toLog("->tid: " + AppParams.tid)
        toLog("->currency: " + AppParams.currency)
        toLog("->installReload: " + AppParams.installReload)
    }
}