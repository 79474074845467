
/** 申请状态 */
export const ApplyStatus = {

    /**
    * 创建
    */
    CREATED: "CREATED",

    /**
     * 打回
     */
    BACKTO: "BACKTO",

    /**
     * 审核中
     */
    SYS_CHECK: "SYS_CHECK",

    /**
     * 拒绝
     */
    SYS_DENIED: "SYS_DENIED",

    /**
     * 机审通过
     */
    SYS_APROVAL: "SYS_APROVAL",

    /**
     * 人审通过
     */
    MANU_APROVAL: "MANU_APROVAL",

    /**
     * 人审拒绝
     */
    MANU_DENIED: "MANU_DENIED",

    /**
     * 电审拒绝
     */
    DS_DENIED: "DS_DENIED",

    /**
     * 电审通过
     */
    DS_APROVAL: "DS_APROVAL",

    /**
     * 订单通过
     */
    SUCCEEDED: "SUCCEEDED",

    /**
     * 订单取消
     */
    CANCEL: "CANCEL",

    /**
    * 未有订单
    */
    NO: "NO"
}

