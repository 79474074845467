import { reqSaveEvent } from "@/store/actions/apiServices";
import { toLog } from "./utils";
import AsToast from "@/components/AsToast/asToast";
import { clickEventFlag, EventTag } from "./pageTag";

export default class AddHomeEvent {

    static canAddHomeEvent = null

    static addBeforeInstallPromptEventListener() {
        const ready = (e) => {
            e.preventDefault();
            this.canAddHomeEvent = (e);
        };
        window.addEventListener("beforeinstallprompt", ready);
    }

    static onHandleAddHomeEventClick() {
        if (window.canAddHomeEvent) {
            reqSaveEvent(clickEventFlag(EventTag.ADD_INSTALL_SHOW));
            window.canAddHomeEvent.prompt()
            window.canAddHomeEvent.userChoice.then((choiceResult) => {
                toLog(choiceResult.outcome)
                if (choiceResult.outcome === 'accepted') {
                    window.dispatchEvent(new Event('onAppAgreeInstall'));
                    reqSaveEvent(clickEventFlag(EventTag.ADD_INSTALL_AGREE));
                } else if (choiceResult.outcome === 'rejected') {
                    reqSaveEvent(clickEventFlag(EventTag.ADD_INSTALL_NOT_AGREE));
                } else {
                    reqSaveEvent(clickEventFlag(EventTag.ADD_INSTALL_DISMISS));
                }
                const event = new Event('onInstallDismiss');
                window.dispatchEvent(event)
            });
            return true;
        }
        else {
            reqSaveEvent(clickEventFlag(EventTag.ADD_INSTALL_NOT_SUPPORT));
            const event = new Event('onInstallDismiss');
            window.dispatchEvent(event)
            return false;
        }
    }

    // 处理书签事件
    static onHandleAddFavoriteEventClick() {
        navigator.clipboard.writeText("https://www.globalpwa.online")
            .then(() => {
                toLog('Text copied to clipboard');
                AsToast.show("Copy success");

            })
            .catch(err => {
                console.error('Failed to copy text:', err);
            });
    }
}