import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
    .use(HttpBackend) // 使用插件加载外部 JSON 文件
    .use(LanguageDetector) // 自动检测用户语言
    .use(initReactI18next)
    .init({
        lng: 'en', // 默认语言
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json'
        }
    });

export default i18n;

// loadPath: '/h5clerecash/locales/{{lng}}/translation.json'
